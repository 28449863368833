import { clone } from "lodash";
import Metronome from "../../metronome/Metronome";
import BasePhysicsSprite from "../base/BasePhysicsSprite";
import BaseProjectile from "../base/BaseProjectile";

export default class NoteMissile extends BaseProjectile {
  private _lockOn = false;
  private _lastKnownPosition: Phaser.Math.Vector2 | undefined;

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    metronome: Metronome,
    name: string,
    projectileSpeed: number,
    // optional
    target?: BasePhysicsSprite, // target shouldn't technically be undefined in this Note Missile class
    collisionSprites: BasePhysicsSprite[] = []
  ) {
    super(scene, x, y, "note-missile", metronome, name, projectileSpeed, target, collisionSprites);
    const swayAngle = Phaser.Math.Between(15, 35);
    this.angle = Phaser.Math.Between(0, 1) ? -swayAngle : swayAngle;

    this.setDrag(0.5);
    this.setDamping(true);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Getters + Setters
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  // public set lockOn(v: boolean) {
  //   this._lockOn = v;
  // }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Functions
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  protected preUpdate(time: number, delta: number): void {
    super.preUpdate(time, delta);

    if (this._lockOn && !this.validate(["HAS_COLLIDED"])) this.angle = this.lockOnTargetLogic();
  }

  /**
   * Spawn animation Note
   */
  public spawn() {
    this.setVisible(true);
    this.sway();
    this.playGenericAnimation("Missile_Note", 0);
    this.once("animationcomplete", () => {
      this.initSpriteCollisionLogic(); // once spawned, enable sprite collision logic
    });
  }

  /**
   * Transform from a Note --> Missile
   * During this time, it will lock onto the player until the projectile is fired
   */
  public transformToMissile() {
    if (this.validate(["HAS_COLLIDED"])) return;

    this.spriteTweenManager.killAllSpriteTweens(); // kill all tweens, as we don't need them anymore, i.e. swaying
    this.lockOnTarget();
    this.playGenericAnimation("Missile_Note_Collapse", 0);
  }

  /**
   * Initially creates a tween that handles going from current rotation to target pointing rotation
   * After that tween is complete, it runs the permanent update lockOnLogic
   */
  private lockOnTarget() {
    // custom sprite tween manager will automatically add the tween to the array + scene, and remove it once complete
    this.spriteTweenManager.addTween({
      targets: this,
      ease: Phaser.Math.Easing.Sine.In,
      delay: 250, // TODO this value is here only to fit the draft animation of the note missiles - for some reason the start of the animation is delayed by about 250ms ... might be an aseprite issue
      duration: 100,
      angle: this.lockOnTargetLogic(),
      onUpdate: (t) => {
        // update angle as the player walks while the tween is still going, otherwise won't look right
        // BUG - there is a weird visual issue with this when the player walks over certain points - but hoping that the duration is short enough where the issue is not visible
        t.updateTo("angle", this.lockOnTargetLogic());
      },
      onComplete: () => {
        this._lockOn = true;
      },
    });
  }

  private lockOnTargetLogic(): number {
    // using WrapDegrees is extremely clutch here, otherwise you'll get this issue: https://www.youtube.com/watch?v=3WloQupH_PQ - 10 seconds
    return Phaser.Math.Angle.WrapDegrees(
      Phaser.Math.RadToDeg(
        Phaser.Math.Angle.Between(this.x, this.y, this.target?.x ?? 0, this.target?.y ?? 0)
      ) - 90
    ); // -90 because of Phaser's right hand rule
  }

  /**
   * Charging up animation - lockOn target is disable
   */
  public chargeUp() {
    if (this.validate(["HAS_COLLIDED"])) return;

    this._lockOn = false; // disable lock on, then charge do charge up animation
    this._lastKnownPosition = clone(this.target?.body.center); // get last known player (center) position, for direction to fire
    // this.once("animationcomplete", () => {});
    this.playGenericAnimation("Missile_Idle", -1, 24); // play indefinitely, until killed
  }

  public fireLogic() {
    this.fireToGivenPosition(this._lastKnownPosition);
    // fade out after some time, then kill (destroy) the sprite completely
    this.spriteTweenManager
      .addTween({
        targets: this,
        alpha: 0, // fade out
        duration: 500,
        delay: 2000, // random time for now
      })
      .once("complete", () => this.kill());
  }

  /**
   * Overriding default collider logic
   */
  public collisionLogic(): void {
    console.log("OVERLAP NOTE", this.name, this.collisionSprites);

    // logic for when a note missile has collided with some sprite (e.g. Player)
    const colliderLogic = () => {
      console.error("HAS COLLIDED");
      this.hasCollided = true;
      this.spriteTweenManager.killAllSpriteTweens(); // immediately stop all tweens
      this.scene.physics.world.removeCollider(collider);

      // reduce velocity as it collides with target
      this.setVelocity(this.body.velocity.x / 2, this.body.velocity.y / 2);
      this.setDrag(0.01);
      this.playGenericAnimation("Missile_Parried", 0);
      this.once("animationcomplete", () => {
        this.kill(); // kill once animation complete
      });
    };

    console.log("collisionSprites", this.collisionSprites);

    const collider = this.scene.physics.add.overlap(this, this.collisionSprites, colliderLogic);
  }

  /**
   * Create tweens to create a nice swaying motion
   * Both get added to the custom tween manager, which can easily handle deletions etc.
   * Will sway indefinitely, until tween is manually killed
   */
  private sway() {
    this.spriteTweenManager.addTween({
      targets: this,
      ease: Phaser.Math.Easing.Sine.InOut,
      duration: Phaser.Math.Between(750, 1250),
      yoyo: true,
      repeat: -1,
      angle: -this.angle, // angle properties
    });
    this.spriteTweenManager.addTween({
      targets: this,
      ease: Phaser.Math.Easing.Sine.InOut,
      duration: Phaser.Math.Between(750, 1250),
      yoyo: true,
      repeat: -1,
      y: Phaser.Math.Between(this.y - 60, this.y + 60), // height property
    });
  }
}
