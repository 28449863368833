import Constants, { MetronomeIntervals } from "../Constants";

class Interval {
  private _scene: Phaser.Scene;
  private _key: string;
  private _color: number;
  private _metronomeIntervalMS: number;
  private _metronomeIntervalOffset: number;
  private _lastTriggerTime: number;
  private _wiggleTime: number;
  private _songEstimatedTime: number;
  private _hasFired: boolean; // acts as a "grace period" / "cooldown" for metronome firing - i.e. stops heartbeats from stacking on top of each other

  constructor(
    scene: Phaser.Scene,
    key: string,
    color: number,
    metronomeIntervalMS: number,
    metronomeIntervalOffset = 0
  ) {
    this._scene = scene;
    this._key = key;
    this._color = color;
    this._metronomeIntervalMS = metronomeIntervalMS;
    this._metronomeIntervalOffset = metronomeIntervalOffset;
    this._lastTriggerTime = 0;
    this._wiggleTime = 0;
    this._songEstimatedTime = 0;
    this._hasFired = false;
  }

  public get color(): number {
    return this._color;
  }

  public get key(): string {
    return this._key;
  }

  public get progress(): number {
    return this._songEstimatedTime % this._metronomeIntervalMS;
  }

  // public reset() {
  // this.lastTriggerTime = 0;
  // this.wiggleTime = 0;
  // this.songEstimatedTime = 0;
  // this.hasFired = false; // you can see the drift it makes by setting this
  // console.warn(`resetting ${this.key} ...`);
  // }

  private printDebug(time: number, delta: number) {
    console.log(
      JSON.stringify(
        {
          time: time,
          delta: delta,
          // startTime: this.songStartTime,
          songEstimatedTime: this._songEstimatedTime,
          metronomeIntervalMS: this._metronomeIntervalMS,
          metronomeIntervalOffset: this._metronomeIntervalOffset,
          progress: this.progress,
          // musicSeek: this.song.seek * 1000,
          wiggleTime: this._wiggleTime,
          lastTriggerTime: this._lastTriggerTime,
          hasFired: this._hasFired,
        },
        null,
        4
      )
    );
  }

  public updateCheckInterval(songEstimatedTime: number, time: number, delta: number): boolean {
    if (this._lastTriggerTime == 0) this._lastTriggerTime = time;
    this._songEstimatedTime = songEstimatedTime + this._metronomeIntervalOffset;
    const timeToNext = this._metronomeIntervalMS - this.progress;
    // check metronomeIntervalMS - progress < delta, e.g. 750 - 700 < (13 / 2), if enough time as passed to trigger a metronomeIntervalMS event
    // check game time > last trigger time + delay (e.g. 2000 > 1000 + 750), meaning the beat is OVERDUE - this is how the beat gets back on time when u tab in / out
    if (
      timeToNext < delta ||
      time > this._lastTriggerTime - this._wiggleTime + this._metronomeIntervalMS
    ) {
      // console.log(timeToNext < delta / 2);
      // console.log(time > this.lastTriggerTime - this.wiggleTime + this.metronomeIntervalMS);
      // this.printDebug(time, delta);

      const msOff =
        timeToNext < this._metronomeIntervalMS / 2
          ? timeToNext
          : timeToNext - this._metronomeIntervalMS;
      this._wiggleTime = msOff > 0 ? 0 : -msOff;
      this._lastTriggerTime = time;

      if (!this._hasFired) {
        // console.warn("hasFired false, able to fire!");

        this._hasFired = true; // set to true - meaning intervals cannot be fired until false
        this._scene.time.delayedCall(
          this._metronomeIntervalMS / 2, // delay is based on the metronome delay divided by some factor, e.g. if metronome delay is 750 and divide factor is 2, then this delay is 375 - so for 375 ms, do not fire heartbeats
          () => {
            // console.log("hasFired = false");
            this._hasFired = false; // then after some delay, set back to false meaning metronome intervals can fire
          }
        );
        return true;
      }
      // console.warn(
      //   `${this.key} hasFired == true - skipping this metronome interval - will fire next interval (i.e. grace period to not spam emits)`
      // );
    }
    return false;
  }
}

export default class Metronome extends Phaser.Events.EventEmitter {
  private scene: Phaser.Scene;
  private metronomeIntervalMS: number;
  private song: Phaser.Sound.HTML5AudioSound;
  private songStartTime: number;
  private songEstimatedTime: number;
  private lastTriggerTime: number;

  private wiggleTime: number;
  private hasFired: boolean; // acts as a "grace period" / "cooldown" for metronome firing - i.e. stops heartbeats from stacking on top of each other

  private intervals: Array<Interval>;

  constructor(scene: Phaser.Scene, song: Phaser.Sound.HTML5AudioSound) {
    super();
    this.scene = scene;
    this.metronomeIntervalMS = (60 / Constants.TEMPO) * 1000; // in ms, get the note
    this.song = song;
    this.songStartTime = 0;
    this.songEstimatedTime = 0;
    this.lastTriggerTime = 0;
    this.wiggleTime = 0;
    this.hasFired = false;

    // IMPORTANT: ordering does actually matter if you are doing quick INSTANT abilities
    // e.g. if you have [x1, x2] metronome intervals setup below, and have an INSTANT ability with intervals set up like: windup of x1, and action of x2, interval x1 will emit first, then interval x2 will emit after ...
    // ... so that means listening to windup (x1) and then IMMEDIATELY executing the ability code to listen to action (x2), will result in these events happening instantaneously, i.e. no ACTION time at all
    // this instantaneous effect can be resolved by simply adding some sort of buffer for the `${this.name}-windup-complete` emitted event, such as a tiny windup animation / time delay (ideal), or reordering the intervals (not ideal)
    this.intervals = [
      // full-bar (x4)
      new Interval(
        scene,
        MetronomeIntervals.INTERVAL_BAR_4,
        0xff0000,
        this.metronomeIntervalMS * 4
      ),

      // half-bar (x2)
      new Interval(
        scene,
        MetronomeIntervals.INTERVAL_BAR_2,
        0xffff00,
        this.metronomeIntervalMS * 2
      ),

      // offset quarter-bar (x1)
      new Interval(
        scene,
        MetronomeIntervals.INTERVAL_BAR_OFFSET_1,
        0x00a5ff,
        this.metronomeIntervalMS,
        this.metronomeIntervalMS / 2
      ),

      // standard quarter-bar (x1)
      new Interval(
        scene,
        MetronomeIntervals.INTERVAL_BAR_1,
        0xffa500,
        this.metronomeIntervalMS * 1
      ),

      // eighth-bar (x0.5)
      new Interval(
        scene,
        MetronomeIntervals.INTERVAL_BAR_05,
        0x0a0aff,
        this.metronomeIntervalMS / 2
      ),

      // sixteenth-bar (x0.25)
      new Interval(
        scene,
        MetronomeIntervals.INTERVAL_BAR_025,
        0xffffff,
        this.metronomeIntervalMS * 0.25
      ),

      // new Interval(scene, "interval-bar-3", 0xff00ff, this.metronomeIntervalMS * 1.5), // be careful when using 3rd bar, as it can be confusing when exactly to reset this metronome interval
    ];

    // copied from cat witch game - is this even worth doing when we can just use the general scene update loop? does it need to be preupdate
    // this.scene.events.on("preupdate", (time: any, delta: any) => {
    //   if (this.song.isPlaying) this.preUpdate(time, delta);
    // });

    this.song.on("play", () => {
      console.debug("music playback has begun");
      this.songStartTime = performance.now();
      this.emit("song-started");
    });

    // IMPORTANT!! if the song has been set to loop, reset all the values TO NOT CAUSE DRIFT (when it begins a loop)
    // if the loop duration is close enough to being accurate, the note "resetting" should be almost unnoticeable
    this.song.on("looped", () => this.reset());
  }

  update(time: number, delta: number) {
    if (!this.song.isPlaying) return; // if the song is not playing, don't do any updates

    this.songEstimatedTime = time - this.songStartTime;

    this.intervals.forEach((i: Interval) => {
      if (i.updateCheckInterval(this.songEstimatedTime, time, delta)) {
        // if (i.key === MetronomeIntervals.INTERVAL_BAR_1) console.error("emit:", i.key);
        this.emit(i.key);
      }
    });
  }

  public get getIntervals(): Array<Interval> {
    return this.intervals;
  }

  public get getMetronomeIntervalMS(): number {
    return this.metronomeIntervalMS;
  }

  public get getSong(): Phaser.Sound.HTML5AudioSound {
    return this.song;
  }

  private reset() {
    console.warn("songStartTime = performance.now()");
    this.songStartTime = performance.now(); // this is vital as it basically restarts the entire metronome - all intervals are reset
    // this.intervals.forEach((i: Interval) => {
    //   i.reset();
    // });
  }
}
