import { isEmpty } from "lodash";
import Constants from "../Constants";
import Boss from "../sprites/boss/Boss";
import Heart from "../sprites/heart/Heart";
import Metronome from "../metronome/Metronome";
import Player from "../sprites/player/Player";
import TubaDwarf from "../sprites/tuba-dwarf/TubaDwarf";
import TutorialScene from "./TutorialScene";

export default class DebugScene extends Phaser.Scene {
  private text1!: Phaser.GameObjects.DynamicBitmapText;
  private playerText!: Phaser.GameObjects.DynamicBitmapText;
  private bossText!: Phaser.GameObjects.DynamicBitmapText;

  ////////////////////////////////////////////////////////////////////////////////
  // Debugging

  // General
  private debugToggle = true;
  private debugGraphics!: Phaser.GameObjects.Graphics;
  private debugDistanceLine!: Phaser.Geom.Line;
  private bossContainer!: Phaser.GameObjects.Container;

  // Metronome Debug Values - to note: if you had a faster BPM, this might break this code
  private metronomeTesterInterval = 25;
  private metronomeTesterLength = 750;
  private metronomeTesterX = Constants.GAME_WIDTH - this.metronomeTesterLength;
  private metronomeOffset = 25;

  // Mouse Settings
  private debugIsDraggingLine = false;
  private mouseOffset = 10;

  ////////////////////////////////////////////////////////////////////////////////
  // Data passed through Tutorial Scene - used for debugging etc.

  private tutorialScene!: TutorialScene;
  private player!: Player;
  private boss!: Boss;
  private heart!: Heart;
  private music!: Phaser.Sound.HTML5AudioSound;
  private metronome!: Metronome;
  private tubaDwarf!: TubaDwarf;

  constructor() {
    super(Constants.DEBUG_SCENE_KEY);
  }

  init(data: TutorialScene) {
    this.tutorialScene = data;
    this.player = data.getPlayer;
    this.boss = data.getBoss;
    this.heart = data.getHeart;
    this.music = data.getMusic;
    this.metronome = data.getMetronome;
    this.tubaDwarf = data.getTubaDwarf;
  }

  create() {
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // STATIC DEBUG INFO

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Init Stuff

    this.debugGraphics = this.add.graphics();
    this.debugDistanceLine = new Phaser.Geom.Line(); // create singular line, inactive at the start, then once pointer is clicked, set first point, secondary click sets the second point, at which it will tell you the distance

    // Debug Keys
    const debugToggleKey = this.input.keyboard.addKey(Constants.DEBUG_KEY);
    const debugShiftKey = this.input.keyboard.addKey("SHIFT");

    // Mouse Coords Stuff
    const mousePointerCoordsDebugText = this.add
      .text(0, 0, "", { fontSize: "2.5em" })
      .setDepth(Constants.DEBUG_Z)
      .setActive(true)
      .setVisible(true);
    const debugDistanceLineTextStartXY = this.add
      .text(0, 0, "", { fontSize: "2.5em" })
      .setDepth(Constants.DEBUG_Z)
      .setActive(false)
      .setVisible(false);
    const debugDistanceLineTextEndXY = this.add
      .text(0, 0, "", { fontSize: "2.5em" })
      .setDepth(Constants.DEBUG_Z)
      .setActive(false)
      .setVisible(false);
    const debugDistanceLineTextDistance = this.add
      .text(0, 0, "", { fontSize: "2.5em" })
      .setDepth(Constants.DEBUG_Z)
      .setActive(false)
      .setVisible(false);

    // // for debugging - make this better later on, currently 4am
    // const minus = this.input.keyboard.addKey("MINUS");
    // minus.on("down", () => {
    // 	this.scene.resume(Constants.TEST_SCENE_KEY);
    // 	this.music.resume();
    // });

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Info Text

    this.infoTextDebugLogic();

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Display Metronome Visually

    this.metronomeDebugLogic();

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Boss Info Text

    this.bossDebugLogic();

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Heart Rhythm

    const heartBounds = this.heart.getBounds();

    // separate graphics object created specifically for heart (static, i.e. will stay permanently)
    this.add
      .graphics()
      .lineStyle(2, 0xff00ff, 1)
      .strokeRectShape(heartBounds)
      .lineStyle(5, 0xff0000, 1)
      .strokeLineShape(
        new Phaser.Geom.Line(
          heartBounds.centerX,
          heartBounds.y,
          heartBounds.centerX,
          heartBounds.y + heartBounds.height
        )
      )
      .setDepth(Constants.DEBUG_Z);

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Checking for all Tweens - useful for debugging Active / Pending tweens, e.g. if Tweens aren't being deleted

    // setInterval(() => {
    //   console.warn(this.tutorialScene.tweens);
    //   const allTweens = this.tutorialScene.tweens.getAllTweens().map((o) =>
    //     // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //     o.targets.map((o2: any) => ({
    //       name: o2?.name,
    //       type: o2?.type,
    //     }))
    //   );
    //   console.warn(`${JSON.stringify(allTweens, null, 2)}`);
    //   // this.tutorialScene.tweens.getAllTweens().forEach((o) => {
    //   //   console.warn(o.targets);
    //   // });
    // }, 10000);

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // ADJUSTABLE DEBUG (WITH TOGGLE)

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Toggle Debug - decided its much easier to just allow for certain things to be toggled, not all
    // If you want to complete disable debug, then turn it off through the actual Phaser Game initialization: physics > arcade > debug: false

    debugToggleKey.on("down", () => {
      // if debug toggle bool is true (enabled) when toggle key is pressed, switch to false and stop rendering all debug stuff - vice versa
      // note that this does NOT remove / delete the debug features, only stop DISPLAYING it
      if (this.debugToggle) {
        this.debugToggle = false; // then switch it to false

        // mouse pointer coords
        mousePointerCoordsDebugText.setActive(false).setVisible(false);
        // debug line
        this.debugGraphics.setActive(false).setVisible(false);
        debugDistanceLineTextStartXY.setActive(false).setVisible(false);
        debugDistanceLineTextEndXY.setActive(false).setVisible(false);
        debugDistanceLineTextDistance.setActive(false).setVisible(false);
      }
      // otherwise false - set up all debugging stuff
      else {
        this.debugToggle = true; // then switch it back to true

        // mouse pointer coords
        mousePointerCoordsDebugText.setActive(true).setVisible(true);
        // debug line
        this.debugGraphics.setActive(true).setVisible(true);
        debugDistanceLineTextStartXY.setActive(true).setVisible(true);
        debugDistanceLineTextEndXY.setActive(true).setVisible(true);
        debugDistanceLineTextDistance.setActive(true).setVisible(true);
      }
      console.warn(`Toggling Debug: ${this.debugToggle}`);
    });

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // MOUSE COORDS LOGIC

    // showing x / y position
    this.input.on("pointermove", (po: Phaser.Input.Pointer) => {
      if (!this.debugToggle) return; // if debug is not enabled, return

      const p = this.debugIfShiftIsDown(po.x, po.y, debugShiftKey);

      // make the debug mouse coords text stay within the bounds of the game
      const pastBoundaryX =
        p.x > Constants.GAME_WIDTH - (mousePointerCoordsDebugText.width + this.mouseOffset * 2);
      const pastBoundaryY =
        p.y > Constants.GAME_HEIGHT - (mousePointerCoordsDebugText.height + this.mouseOffset * 2);

      // if past both boundaries
      if (pastBoundaryX && pastBoundaryY) {
        mousePointerCoordsDebugText.x =
          Constants.GAME_WIDTH - mousePointerCoordsDebugText.width - this.mouseOffset;
        mousePointerCoordsDebugText.y =
          Constants.GAME_HEIGHT - mousePointerCoordsDebugText.height - this.mouseOffset * 2;
      }

      // if past the X boundary (and not Y)
      if (pastBoundaryX && !pastBoundaryY) {
        mousePointerCoordsDebugText.x =
          Constants.GAME_WIDTH - mousePointerCoordsDebugText.width - this.mouseOffset;
        mousePointerCoordsDebugText.y = p.y;
      }

      // if past the Y boundary (and not X)
      if (pastBoundaryY && !pastBoundaryX) {
        mousePointerCoordsDebugText.x = p.x + this.mouseOffset;
        mousePointerCoordsDebugText.y =
          Constants.GAME_HEIGHT - mousePointerCoordsDebugText.height - this.mouseOffset * 2;
      }

      // else
      if (!pastBoundaryX && !pastBoundaryY) {
        mousePointerCoordsDebugText.x = p.x + this.mouseOffset;
        mousePointerCoordsDebugText.y = p.y;
      }

      mousePointerCoordsDebugText.text = `x: ${Phaser.Math.RoundTo(
        p.x,
        0
      )}\ny: ${Phaser.Math.RoundTo(p.y, 0)}`;
    });

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // CREATE LINE AND MEASURE DISTANCE LOGIC - not super optimised but idc, especially the reset logic

    // line start (initial drag)
    this.input.on("pointerdown", (po: Phaser.Input.Pointer) => {
      if (!this.debugToggle) return; // if debug is not enabled, return

      // reset drawn line
      debugDistanceLineTextStartXY.setText("").setActive(false).setVisible(false);
      debugDistanceLineTextDistance.setText("").setActive(false).setVisible(false);
      debugDistanceLineTextEndXY.setText("").setActive(false).setVisible(false);
      this.debugGraphics.clear();

      // if RMB, exit as we just wanted to clear debug
      if (po.rightButtonDown()) return;

      this.debugIsDraggingLine = true;
      debugDistanceLineTextStartXY.setActive(true).setVisible(true); // line START text
      debugDistanceLineTextDistance.setActive(true).setVisible(true); // line DISTANCE text
      const p = this.debugIfShiftIsDown(po.x, po.y, debugShiftKey);

      this.debugDistanceLine.setTo(p.x, p.y, p.x, p.y); // x2 / y2 not necessary, it's just so the line doesn't bug out when you first click

      debugDistanceLineTextStartXY.text = `x: ${Phaser.Math.RoundTo(
        p.x,
        0
      )}\ny: ${Phaser.Math.RoundTo(p.y, 0)}`;
      debugDistanceLineTextStartXY.x = this.debugDistanceLine.x1 + this.mouseOffset;
      debugDistanceLineTextStartXY.y = this.debugDistanceLine.y1;
    });

    // line end
    this.input.on("pointerup", (po: Phaser.Input.Pointer) => {
      if (!this.debugToggle || !po.leftButtonReleased()) return; // if debug is not enabled, return

      this.debugIsDraggingLine = false;
      debugDistanceLineTextEndXY.setActive(true).setVisible(true); // line END text
      const p = this.debugIfShiftIsDown(po.x, po.y, debugShiftKey);

      debugDistanceLineTextEndXY.text = `x: ${Phaser.Math.RoundTo(
        p.x,
        0
      )}\ny: ${Phaser.Math.RoundTo(p.y, 0)}`;
      debugDistanceLineTextEndXY.x = this.debugDistanceLine.x2 + this.mouseOffset;
      debugDistanceLineTextEndXY.y = this.debugDistanceLine.y2;
    });

    // while dragging, update line
    this.input.on("pointermove", (po: Phaser.Input.Pointer) => {
      if (!this.debugToggle || !this.debugIsDraggingLine) return; // if debug is not enabled OR we are not dragging line, return

      // update green line while dragging
      this.debugGraphics.clear();
      this.debugGraphics
        .lineStyle(2, 0x00ff00)
        .strokeLineShape(this.debugDistanceLine)
        .setDepth(Constants.DEBUG_Z);

      const p = this.debugIfShiftIsDown(po.x, po.y, debugShiftKey);

      this.debugDistanceLine.x2 = p.x;
      this.debugDistanceLine.y2 = p.y;

      // calculate where the DISTANCE text should go
      debugDistanceLineTextDistance.x =
        (this.debugDistanceLine.x1 + this.debugDistanceLine.x2) / 2 -
        debugDistanceLineTextDistance.width / 2;
      debugDistanceLineTextDistance.y =
        (this.debugDistanceLine.y1 + this.debugDistanceLine.y2) / 2 -
        debugDistanceLineTextDistance.height / 2;

      debugDistanceLineTextEndXY.text = `x: ${Phaser.Math.RoundTo(
        p.x,
        0
      )}\ny: ${Phaser.Math.RoundTo(p.y, 0)}`;
      debugDistanceLineTextEndXY.x = this.debugDistanceLine.x2 + this.mouseOffset;
      debugDistanceLineTextEndXY.y = this.debugDistanceLine.y2;

      // update line distance while dragging
      debugDistanceLineTextDistance.text = `${Phaser.Math.RoundTo(
        Phaser.Math.Distance.BetweenPoints(
          { x: this.debugDistanceLine.x1, y: this.debugDistanceLine.y1 },
          { x: this.debugDistanceLine.x2, y: this.debugDistanceLine.y2 }
        ),
        0
      )}`;
    });

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // EXPERIMENTAL DEBUG CODE

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // DAT GUI

    // const sm = new dat.GUI().addFolder("this.music");
    // sm.add(this.music, "seek", 0, this.music.duration).step(0.001).listen();
    // sm.add(this.music, "rate", 0.5, 2).listen();
    // sm.add(this.music, "detune", -1200, 1200).step(50).listen();
    // sm.add(this.music, "loop").listen();
    // sm.add(this.music, "play");
    // sm.add(this.music, "pause");
    // sm.add(this.music, "resume");
    // sm.add(this.music, "stop");
    // sm.open();

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // general heartbeat debugging

    // this.input.on("pointerdown", () => {
    //   this.heart.getHeartbeatLeftGroup.fireHeartbeat();
    //   this.heart.getHeartbeatRightGroup.fireHeartbeat();
    //   // console.log(
    //   //   "\tGOOD:",
    //   //   this.heart.getHeartbeatLeftGroup.getCollisionListGood.map((e) => e.name)
    //   // );
    //   // console.log(
    //   //   "\tGREAT:",
    //   //   this.heart.getHeartbeatLeftGroup.getCollisionListGreat.map((e) => e.name)
    //   // );
    // });

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // // pause logic - for debugging - make this better later on, currently 4am

    // const pauseKey = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.L);
    // pauseKey.on("down", () => {
    //   // console.log(
    //   //   "\tGOOD:",
    //   //   this.heart.getHeartbeatLeftGroup.getCollisionListGood.map((e) => e.name)
    //   // );
    //   // console.log(
    //   //   "\tGREAT:",
    //   //   this.heart.getHeartbeatLeftGroup.getCollisionListGreat.map((e) => e.name)
    //   // );
    //   this.scene.pause();
    //   this.music.pause();
    // });
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // Methods

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Boss Debug Logic Function

  /**
   * Displaying info directly on the boss sprite
   * Note: setPosition code within "Update" method (below) allows for this info text to follow the boss as he moves around
   * Again, not optimised - manually typing out x / y positions, rather than generating dynamically based on the text size adjacent to it
   */
  public bossDebugLogic() {
    this.bossContainer = this.add.container().setName("BossDebugContainer");
    const bossFSMText = this.add.dynamicBitmapText(0, 150, "debug", "", 60).setOrigin(0.5);
    const bossCurrentRespirationText = this.add
      .dynamicBitmapText(0, bossFSMText.y + 50, "debug", "", 32)
      .setOrigin(0.5);
    // const bossSelectedRespirationText = this.add
    //   .dynamicBitmapText(0, bossCurrentRespirationText.y + 75, "debug", "", 32)
    //   .setOrigin(0.5);
    const bossAbilityList = this.add
      .dynamicBitmapText(0, bossCurrentRespirationText.y + 75, "debug", "", 32)
      .setOrigin(0.5)
      .setCenterAlign();
    const bossCurrentAbility = this.add
      .dynamicBitmapText(0, bossAbilityList.y + 75, "debug", "", 32)
      .setOrigin(0.5);

    this.registry.events.on("changedata", (game: Phaser.Game, event: string, o: unknown) => {
      if (event === "bossData" && o instanceof Boss) {
        bossFSMText.setText(`${o.bossFSM.getState}`);
        bossCurrentRespirationText.setText(`Respiration: ${o.resourceValueCurr}`);
        // bossSelectedRespirationText.setText(`${o.abilityGraph.startingCost}`);
        bossAbilityList.setText(
          `AbilityList\n${
            !isEmpty(o.abilityGraph.getAllValues().abilitiesToUse)
              ? o.abilityGraph.getAllValues().abilitiesToUse
              : " ... "
          }`
        );
        bossCurrentAbility.setText(
          `(${o.abilityGraph.currentExecutedAbility?.name ?? " ... "}, ${
            o.abilityGraph.currentExecutedAbility?.cost ?? " ... "
          })`
        );
      }
    });

    this.bossContainer.add([
      bossFSMText,
      bossCurrentRespirationText,
      // bossSelectedRespirationText,
      bossCurrentAbility,
      bossAbilityList,
    ]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Info Text Function

  /**
   * Text relating to all general info in the game - adjust as needed
   * Not completely optimised, but not a huge deal as it's just debug stuff - height is generated through empty arrays - could make this better
   */
  public infoTextDebugLogic() {
    // Includes Player / Boss etc.

    // const cam = this.cameras.main;
    // cam.setSize(400, 275);
    // cam.setBackgroundColor("rgba(0,0,0,0.5)");
    const infoTextBackground = this.add
      .rectangle(0, 0, 500, 0, 0x000000, 0.5)
      .setOrigin(0)
      .setDepth(-1);

    this.text1 = this.add.dynamicBitmapText(25, 25, "debug", "", 24).setText([
      "WASD: Movement",
      "SPACE: Dash",
      "T: Toggle Debug",
      // "FPS: " + this.game.loop.actualFps, // not a good idea to use a custom FPS meter as it lags out the game, use chrome's built in FPS meter
    ]);

    // player info text - Array(6) means 6 lines in height
    this.playerText = this.add.dynamicBitmapText(25, 0, "debug", Array(6).fill("text"), 24);

    // boss info text
    this.bossText = this.add.dynamicBitmapText(25, 0, "debug", Array(6).fill("text"), 24);

    this.registry.events.on("changedata", (game: Phaser.Game, event: string, o: unknown) => {
      if (event === "playerData" && o instanceof Player) {
        this.playerText.setText([
          "Player FSM (Movement): " + o.getPlayerMovementFSM.getState,
          "Player FSM (Combat): " + o.getPlayerCombatFSM.getState,
          "Player Dash Available: " + o.getDashAvailable,
          "Player X Velocity: " + Math.round(o.body.velocity.x),
          "Player Y Velocity: " + Math.round(o.body.velocity.y),
          "Player Current Direction: " + o.getCurrentDirection,
        ]);
      }
      if (event === "bossData" && o instanceof Boss) {
        let tempMaxCost = o.maxRespirationCost;
        if (o.resourceValueCurr < o.maxRespirationCost) tempMaxCost = o.resourceValueCurr;

        this.bossText.setText([
          "Boss FSM: " + o.bossFSM.getState,
          "Boss Current Ability: " + (o.abilityGraph.currentExecutedAbility?.name ?? "No Ability"),
          "Boss Current Respiration: " + o.resourceValueCurr,
          "Boss Respiration Cost Range: (" + o.minRespirationCost + ", " + tempMaxCost + ")",
          "Boss Cost Used: " +
            " (" +
            o.abilityGraph.startingCost +
            " - " +
            o.abilityGraph.remainingCost +
            ") == " +
            (o.abilityGraph.startingCost - o.abilityGraph.remainingCost),
          "Boss Tween List: " +
            (!isEmpty(o.spriteTweenManager.spriteTweenList)
              ? Object.keys(o.spriteTweenManager.spriteTweenList)
              : "None"),
        ]);
      }
    });

    // set the positions of all info text using quick maffs - cba to do properly
    this.playerText.y = 25 * 2 + this.text1.height;
    this.bossText.y = 25 * 3 + this.text1.height + this.playerText.height;
    infoTextBackground.height =
      25 * 4 + this.text1.height + this.playerText.height + this.bossText.height;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Metronome Debugging

  /**
   * Display all metronome beats - OPTIMISED CODE, NO NEED TO ADJUST
   * able to add / remove intervals within the metronome class itself, and this code will update its size automatically
   */
  public metronomeDebugLogic() {
    this.add
      .rectangle(
        this.metronomeTesterX - this.metronomeOffset,
        0,
        Constants.GAME_WIDTH - this.metronomeTesterX + this.metronomeOffset,
        this.metronomeTesterInterval * this.metronome.getIntervals.length -
          (this.metronomeTesterInterval - this.metronomeOffset * 2),
        0x000000,
        0.5
      )
      .setOrigin(0)
      .setDepth(-1);

    this.metronome.getIntervals.forEach((e, i) => {
      // console.log(e.getKey, i);

      this.metronome.on(e.key, () => {
        const temp = this.add.circle(
          this.metronomeTesterX,
          this.metronomeOffset + this.metronomeTesterInterval * i,
          10,
          e.color
        );
        this.add.tween({
          targets: temp,
          x: temp.x + this.metronomeTesterLength,
          duration: 5000,
          onComplete: () => {
            temp.destroy();
          },
        });
      });
    });
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // General

  /**
   * If shift is being held down, manipulate x / y coord values to "snap to" grid
   * @param x
   * @param y
   * @param shift
   * @returns
   */
  public debugIfShiftIsDown(x: number, y: number, shift: Phaser.Input.Keyboard.Key) {
    let px = x;
    let py = y;
    if (shift.isDown) {
      // override if shift key is down
      px = Phaser.Math.Snap.To(x, 20);
      py = Phaser.Math.Snap.To(y, 20);
    }
    return { x: px, y: py };
  }

  /**
   * Using Update method for any debugging logic that needs to be refreshed every frame - i.e. debug graphics that need to follow the related object
   */
  update() {
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Boss

    this.bossContainer.setPosition(this.boss.x, this.boss.getBounds().top);
  }
}
