import Constants, { AbilityMetronomeIntervals, AbilityType } from "../Constants";
import { convertMetronomeIntervalToPeriod } from "../helpers/Helpers";
import Metronome from "../metronome/Metronome";

export interface BaseAbilityType {
  initAbility: () => void;
  abilityWindUp: () => void;
  abilityTension: () => void;
  abilityAction: () => void;
  abilityInterrupted: () => void;
}

export class BaseAbility extends Phaser.Events.EventEmitter implements BaseAbilityType {
  /**
   * Type of ability - INSTANT or CHANNEL. Depending on which type, it will either listen to the metronome (INSTANT), or estimate a length (CHANNEL)
   *
   * If INSTANT:
   * - `windup` metronome interval represents the START of the ability
   * - `action` metronome interval represents the END of the ability
   *
   * If CHANNEL:
   * - `windup` metronome interval represents the START of the ability
   * - `action` metronome interval represents the LENGTH of the ability, counting the SHORTEST METRONOME INTERVAL#
   *   - e.g. if I chose `INTERVAL_BAR_2` as the action, then the channel will last a total of 1 yellow beat (i.e. 2 orange beats)
   */
  private _abilityType: AbilityType;

  // Name of the ability
  private _name: string;

  // metronome instance to allow for timings
  private _metronome: Metronome;

  // metronome intervals
  private _metronomeInterval: AbilityMetronomeIntervals;

  // Probability weight of this ability being chosen (arbitrary)
  private _weight: number;

  // The associated cost of using this ability (e.g. respiration / mana) - can be optional
  private _cost: number;

  ////////////////////////////////////////
  // other vars

  // keep track of SHORTEST_METRONOME_INTERVAL counter - for channeling abilities
  private _counterInterval = 0;

  // keep track of how many times to repeat - for channeling abilities
  private _timesToRepeat: number;

  constructor(
    abilityType: AbilityType,
    name: string,
    metronome: Metronome,
    metronomeInterval: AbilityMetronomeIntervals,
    weight: number,
    cost?: number
  ) {
    super();
    this._abilityType = abilityType;
    this._name = name;
    this._metronome = metronome;
    this._metronomeInterval = metronomeInterval;
    this._weight = weight;
    this._cost = cost ?? 0; // if an ability has no cost associated, just default to 0 - cost deduction logic (via emit) won't be called anyway

    this._timesToRepeat =
      convertMetronomeIntervalToPeriod(this._metronomeInterval.action) /
      convertMetronomeIntervalToPeriod(Constants.SHORTEST_METRONOME_INTERVAL);
  }

  public getAllValues() {
    return {
      name: this._name,
      cost: this._cost,
      weight: this._weight,
    };
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Getters + Setters
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  public get name(): string {
    return this._name;
  }

  public get metronome(): Metronome {
    return this._metronome;
  }

  public get metronomeInterval(): AbilityMetronomeIntervals {
    return this._metronomeInterval;
  }

  public get weight(): number {
    return this._weight;
  }

  public get cost(): number {
    return this._cost;
  }

  public set cost(v: number) {
    this._cost = v;
  }

  /**
   * This method should be called as soon as the state transitions from THINK --> ACTION
   *
   * Once called, the first event listener for WINDUP will be initiated (once), and will execute the WINDUP logic once the specified metronome interval is emitted
   *
   * Since we do not know really the duration of the windup animation and the next metronome interval, TENSION is to fill the gap between the WINDUP <--> ACTION duration
   *  This is where you will usually see the sprite jitter or repeat an animation indefinitely, or just completely stay idle until the next beat is called
   *
   * Finally, ACTION is called once tension is fulfilled
   */
  public executeAbility() {
    // WINDUP event listener - very first thing that is called
    this._metronome.once(this._metronomeInterval.windup, () => {
      // TENSION event listener - called when WINDUP finished - code needs to be ordered first to listen to event
      // tension will play indefinitely, until the ACTION metronome interval is reached, and animation is overruled
      this.once(`${this._name}-windup-complete`, () => {
        if (Constants.ENABLE_ABILITY_LOGS) console.warn(`${this._name} TENSION`);

        this.abilityTension();

        // if the ability is an INSTANT ability, then run ACTION when metronome interval occurs
        if (this._abilityType === AbilityType.INSTANT) {
          this._metronome.once(this._metronomeInterval.action, () => {
            if (Constants.ENABLE_ABILITY_LOGS)
              console.warn(`${this._name} ACTION (${this._abilityType})`);

            this.emit(Constants.ABILITY_ACTIONED, this._cost); // emit event so that ability graph can listen for additional cost logic, i.e. to reduce mana
            this.abilityAction(); // do ability logic
          });
        }
      });

      if (Constants.ENABLE_ABILITY_LOGS) console.warn(`${this._name} WINDUP`);

      this.abilityWindUp();

      // if the ability is a CHANNEL ability, then run ACTION as soon as WINDUP is called
      if (this._abilityType === AbilityType.CHANNEL) {
        if (Constants.ENABLE_ABILITY_LOGS)
          console.warn(`${this._name} ACTION (${this._abilityType})`);

        this._metronome.on(Constants.SHORTEST_METRONOME_INTERVAL, this._actionChannel, this);
      }
    });
  }

  /**
   * Private method for Code Maintenance
   */
  private _actionChannel() {
    if (this._counterInterval == 0) {
      if (Constants.ENABLE_ABILITY_LOGS)
        console.log(`ignoring first channel beat ${Constants.SHORTEST_METRONOME_INTERVAL}`);
      this._counterInterval++;
      return;
    }

    const costToDeduct = this._cost / this._timesToRepeat;
    this.emit(Constants.ABILITY_ACTIONED, costToDeduct); // emit with cost deduction value, if needed for this ability

    if (Constants.ENABLE_ABILITY_LOGS)
      console.log("counter", this._counterInterval, "timesToRepeat", this._timesToRepeat);
    // if (this.counterInterval025 == 1) this._action();

    if (this._counterInterval < this._timesToRepeat) {
      this._counterInterval++;
      return;
    }

    this._metronome.off(Constants.SHORTEST_METRONOME_INTERVAL, this._actionChannel, this); // remove MetronomeIntervals.INTERVAL_BAR_025 event listener, since its not 'once'
    this._counterInterval = 0; // reset counter

    // code below allows for abilities to be chained / NOT chained - i.e. instantaneous "bug"
    // the reason the instantaneous bug happens is because when the INTERVAL_BAR_025 ends (smallest interval), all other intervals need to still be processed, so events will get called INSTANTLY (at least for CHANNEL type abilities)
    // this.boss.getMetronome.once(this.metronomeInterval.windup, () => this.abilityAction());

    // probably better to be calling the abilityAction function multiple times like the code above? but then don't know how to handle OFF events etc.
    this.abilityAction(); // once counter is reached, do ability logic
  }

  /*
   * Every ability is responsible for emitting an END event - i.e. after animation complete
   * Cannot decide this generically as each ability resolution is different
   */

  /**
   *
   */
  public initAbility() {
    console.error("No Init Ability Implemented! (Called on Base Ability)");
  }

  /**
   *
   */
  public abilityWindUp() {
    console.error("No Ability Windup Implemented! (Called on Base Ability)");
  }

  /**
   * (tension is not needed)
   */
  public abilityTension() {
    console.error("No Ability Tension Implemented! (Called on Base Ability)");
  }

  /**
   * ${this.name}-windup-complete
   */
  public abilityAction() {
    console.error("No Ability Action Implemented! (Called on Base Ability)");
  }

  /**
   * When the boss is stunned in some way, each ability will have to handle how to pause /
   */
  public abilityInterrupted() {
    console.error("No Ability Interrupted Implemented! (Called on Base Ability)");
  }
}
