import Boss from "../sprites/boss/Boss";
import Player from "../sprites/player/Player";
import Constants from "../Constants";
import Heart from "../sprites/heart/Heart";
import Metronome from "../metronome/Metronome";
// import { LAG_O_METER } from "../helpers/Helpers";
import OrganShooter from "../sprites/organ-shooter/OrganShooter";
import TubaDwarf from "../sprites/tuba-dwarf/TubaDwarf";
import BaseSprite from "../sprites/base/BaseSprite";
import NoteMissile from "../sprites/note-missile/NoteMissile";

export default class TutorialScene extends Phaser.Scene {
  private gameWidth: number;
  private gameHeight: number;

  private player!: Player;
  private boss!: Boss;
  private heart!: Heart;
  private music!: Phaser.Sound.HTML5AudioSound;
  private metronome!: Metronome;

  ////////////////////////////////////////////////////////////////////////////////
  // TESTING

  private organShooterTest!: OrganShooter;
  private tubaDwarf!: TubaDwarf;

  constructor() {
    super(Constants.TUTORIAL_SCENE_KEY);
    this.gameWidth = Constants.GAME_WIDTH;
    this.gameHeight = Constants.GAME_HEIGHT;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Getters + Setters
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  public get getPlayer(): Player {
    return this.player;
  }

  public get getBoss(): Boss {
    return this.boss;
  }

  public get getHeart(): Heart {
    return this.heart;
  }

  public get getMusic(): Phaser.Sound.HTML5AudioSound {
    return this.music;
  }

  public get getMetronome(): Metronome {
    return this.metronome;
  }

  public get getTubaDwarf(): TubaDwarf {
    return this.tubaDwarf;
  }

  init() {
    console.clear();
    // console.log(game);
    // console.log(this.sys);
  }

  create() {
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Background

    const bg = this.add.image(0, 0, "bg").setOrigin(0, 0); //.setDisplaySize(this.gameWidth, this.gameHeight);
    bg.setScale(Math.max(this.gameWidth / bg.width, this.gameHeight / bg.height));

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Music

    this.sound.pauseOnBlur = false;

    const musicConfig = {
      mute: false,
      volume: 0.1,
      detune: 0,
      seek: 0,
      loop: true,
      delay: 0,
    };

    this.music = this.sound.add("music") as Phaser.Sound.HTML5AudioSound; // casting here to gain access to property 'seek' - maybe better way of doing this, don't want to have to cast

    const markers = [
      { name: "intro", start: 0, duration: 36.1 },
      { name: "mainLoop", start: 36.158, duration: 48.014 },
      // { name: "mainLoop", start: 36.158, duration: 11.987 },
      // { name: "mainLoop", start: 36.158, duration: 2 }, // testing wrong intervals
      // { name: "mainLoop", start: 0.0789, duration: (0.4539 - 0.0789) },  //BPM160
    ];
    markers.forEach((e) => this.music.addMarker(e));

    this.time.delayedCall(1000, () => {
      // console.log("start song");
      this.music.play(markers[1].name, musicConfig);

      // this.time.addEvent({
      // 	callback: () => this.heart.checkCollision(this),
      // 	delay: 750,
      // 	loop: true
      // });
    });

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Metronome

    this.metronome = new Metronome(this, this.music);

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Player

    this.player = new Player(this, 100, 500, this.metronome, Constants.PLAYER_NAME);

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Boss

    this.boss = new Boss(
      this,
      this.gameWidth / 2,
      this.gameHeight - 600,
      this.metronome,
      "boss",
      0, // min Respiration
      50 // max Respiration
    );

    // example setting collision
    // this.player.collisionSprites = [...this.player.collisionSprites, this.boss];

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Note Missile

    // TODO eventually change the hit-box of the note (when real animations come through)
    // const notesTestArr: NoteMissile[] = [];

    // for (let i = 0; i < 1; i++) {
    //   const noteToAdd = new NoteMissile(
    //     this,
    //     300 + 75 * i,
    //     750,
    //     this.metronome,
    //     "note_missile_test",
    //     2000,
    //     this.player
    //   ).setScale(2);
    //   noteToAdd.setScale(2);
    //   noteToAdd.spawn();

    //   setTimeout(() => {
    //     // only if note is active AND an animation is NOT playing
    //     // console.error("TRANSFORM TO MISSILE");
    //     noteToAdd.transformToMissile();
    //     setTimeout(() => {
    //       // console.error("CHARGE UP AND FIRE");
    //       noteToAdd.chargeUpAndFire();
    //     }, 2000);
    //   }, 2000);

    //   notesTestArr.push(noteToAdd);
    // }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Initialise all game object collisions, now all sprites are created

    // this.player.initSpriteCollisionLogic([this.boss]);
    this.boss.initSpriteCollisionLogic([this.player]);
    // notesTestArr.forEach((o) => o.initSpriteCollisionLogic([this.player]));

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Heart

    this.heart = new Heart(this, this.gameWidth / 2, 0 + 90, this.metronome, "", this.player);
    // this is causing heartbeats to be out of sync from the heartbeat center btw - this is a James image issue - FIX
    // this.heart.setX(this.heart.x + 2); // TEMPORARY FIX - heart sprite is 2 pixels out from center, shouldn't really have to do this

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Organ Shooter (WIP)

    // this.organShooterTest = new OrganShooter(
    //   this,
    //   this.gameWidth - 510,
    //   this.gameHeight / 2,
    //   this.metronome,
    //   300
    // );

    // this.organShooterTest.initOrganProjectiles(this.player);

    // test this idea out: making a group of 5 organs together and animating them at different speeds / intervals - should be fun

    // new OrganShooter(this, this.gameWidth - 410, this.gameHeight / 2, this.metronome, 300);
    // .initOrganProjectiles(this.player);

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Tuba Dwarf (WIP)

    // this.tubaDwarf = new TubaDwarf(
    //   this,
    //   this.gameWidth / 2,
    //   this.gameHeight / 2,
    //   this.metronome,
    //   this.player
    // );
    // this.tubaDwarf.setMoveLikeBird = true;
    // this.tubaDwarfTest.moveInRandomDirection();

    // adding more just as example
    // const tb1 = new TubaDwarf(
    //   this,
    //   this.gameWidth / 2 + 100,
    //   this.gameHeight / 2,
    //   this.metronome,
    //   this.player
    // );
    // tb1.moveInRandomDirection();
    // tb1.toggleTubaDebug(false);
    // const tb2 = new TubaDwarf(
    //   this,
    //   this.gameWidth / 2 - 100,
    //   this.gameHeight / 2,
    //   this.metronome,
    //   this.player
    // );
    // tb2.moveInRandomDirection();
    // tb2.toggleTubaDebug(false);

    //

    //

    //

    //

    //

    //

    //

    //

    //

    //

    //

    //

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // TESTING

    //

    //

    //

    //

    //

    //

    //

    //

    //

    //

    //

    //

    // Health Bar Animations / Heart Beat Wave

    // the actual heart
    const heartBeatTest = new BaseSprite(this, 2300, 700, "heartBeatTest", this.metronome, "");

    // the heart mask animation (hollow heart)
    const heartBeatMaskHollow = new BaseSprite(
      this,
      heartBeatTest.x,
      heartBeatTest.y,
      "heartBeatMaskTest",
      this.metronome,
      ""
    ).setDepth(heartBeatTest.depth + 1);

    const heartBeatMaskHollowBounds = heartBeatMaskHollow.getBounds();

    // the heart beat wave
    const heartBeatWave = new BaseSprite(
      this,
      heartBeatMaskHollow.x,
      heartBeatMaskHollowBounds.bottom - 30, // 30 is arbitrary number to add to get the wave aligned with the box mask
      "heartBeatWave",
      this.metronome,
      ""
    )
      .setDepth(heartBeatMaskHollow.depth + 10)
      .setScale(1.25)
      .setMask(heartBeatMaskHollow.createBitmapMask());

    // this.add
    //   .graphics()
    //   .lineStyle(2, 0xff00ff, 1)
    //   .strokeRectShape(heartBeatMaskHollowBounds)
    //   .lineStyle(5, 0xff0000, 1)
    //   .strokeLineShape(
    //     new Phaser.Geom.Line(
    //       heartBeatMaskHollowBounds.centerX,
    //       heartBeatMaskHollowBounds.y,
    //       heartBeatMaskHollowBounds.centerX,
    //       heartBeatMaskHollowBounds.y + heartBeatMaskHollowBounds.height
    //     )
    //   )
    //   .setDepth(Constants.DEBUG_Z);

    heartBeatTest.play({
      key: "HeartBeat",
      repeat: -1,
    });
    heartBeatMaskHollow.play({
      key: "Mask_Heart",
      repeat: -1,
    });
    heartBeatWave.play({
      key: "Mask_Hearts",
      repeat: -1,
    });

    const maskGraphics = this.add
      .graphics()
      .fillStyle(0x0000ff, 0.5)
      .fillRect(
        heartBeatMaskHollowBounds.x,
        heartBeatMaskHollowBounds.y,
        heartBeatMaskHollowBounds.width,
        heartBeatMaskHollowBounds.height
      )
      .setDepth(1);

    // cant get containers to work for some reason

    // https://stackoverflow.com/questions/60103979/issue-with-moving-masks-in-phaser-3

    // const container1 = this.add.container(0, 0);
    // container1.add([maskGraphics, heartBeatWave]);
    // heartBeatMaskHollow.setMask(container1.createBitmapMask());

    heartBeatMaskHollow.setMask(maskGraphics.createGeometryMask());

    // tween the mask
    this.add.tween({
      targets: maskGraphics,
      y: maskGraphics.y - heartBeatMaskHollowBounds.height,
      duration: 6000,
      repeat: -1,
      yoyo: true,
    });

    this.add.tween({
      targets: heartBeatWave,
      y: heartBeatWave.y - heartBeatMaskHollowBounds.height,
      duration: 6000,
      repeat: -1,
      yoyo: true,
    });

    //

    //

    //

    //

    //

    //

    //

    //

    //

    //

    //

    //

    // obj2.setMask(mask);

    // this.time.delayedCall(2500, () => {
    // 	console.log("MUSIC START");
    // 	this.metronome.getSong.play();
    // });

    // boss.playAnimation("Attack");
    // boss.setVelocity(0, 400);
    // boss.setBounce(1, 1);

    // this.game.events.on(Phaser.Core.Events.BLUR, () => {
    // 	this.scene.pause();
    // 	this.music.pause();
    // 	// instead start some pause scene
    // });

    // this.game.events.on(Phaser.Core.Events.FOCUS, () => {
    // 	this.scene.resume();
    // 	this.music.resume();
    // });

    // UI Scene
    this.scene.launch(Constants.UI_SCENE_KEY, { player: this.player });

    // If debugging is enabled, run the debugging scene by default, which contains ALL debugging features for ALL sprites, game objects, etc.
    // This way, we can properly contain it, and turn debug on and off with just the line below - much easier to maintain
    if (this.physics.world.drawDebug) this.scene.launch(Constants.DEBUG_SCENE_KEY, this);
  }

  update(time: number, delta: number) {
    // intentional lag for testing things - USE WITH CAUTION
    // LAG_O_METER(this);

    // update entities
    this.metronome.update(time, delta);
    this.player.update(time);
    this.boss.update(time);
    this.heart.update(this, time);

    // event emitters for DebugScene
    this.registry.set("playerData", this.player);
    this.registry.set("bossData", this.boss);
  }
}
