import { DirectionType } from "../../Constants";
import Player, { IKeys, PlayerMovementStates } from "./Player";
import StateMachine, { State } from "../../states/StateMachine";

/**
 * /////////////////////////////////////////////////////////////////////////////////////////////////////////
 *
 * Player Idle State
 *
 * Idle can transition to:
 *  - Move
 *
 * (e.g. you are not able to dash while in Idle state - have to be moving first)
 */
export class IdleState implements State<Player> {
  stateMachine: StateMachine<Player>;

  constructor(stateMachine: StateMachine<Player>) {
    this.stateMachine = stateMachine;
  }

  enter(scene: Phaser.Scene, player: Player) {
    // console.log(" > entering idle...");

    player.setVelocity(0);
    player.setPlayerMoveState(DirectionType.IDLE);
    player.emit("enter-idle");
    // if you want the player sprite to reset its animation back to idle (front facing) after moving has finished, then uncomment the line below - otherwise the player will keep the last direction it was moving
    // player.anims.play(PlayerAnimationIndex[player.getCurrentDirection], true);
    // player.anims.stop();
  }

  update(scene: Phaser.Scene, player: Player) {
    // ====================================================================
    // Transitions

    // // hurt if H key input (just for demo purposes)
    // if (Phaser.Input.Keyboard.JustDown(HKey)) {
    //   this.stateMachine.transition("hurt");
    //   return;
    // }

    // transition to move if pressing movement keys
    if (checkIfMovementKeysPressed({ ...player.getKeys })) {
      this.stateMachine.transition(PlayerMovementStates.MOVE);
      return;
    }
  }

  exit(scene: Phaser.Scene, player: Player) {
    // console.log(" < exiting idle...");
    player.emit("exit-idle");
  }
}

/**
 * /////////////////////////////////////////////////////////////////////////////////////////////////////////
 *
 * Player Move State
 *
 * Move can transition to:
 *  - Idle
 *  - Dash
 */
export class MoveState implements State<Player> {
  stateMachine: StateMachine<Player>;

  constructor(stateMachine: StateMachine<Player>) {
    this.stateMachine = stateMachine;
  }

  enter(scene: Phaser.Scene, player: Player) {
    // console.log(" > entering move...");
    player.emit("enter-move");
  }

  update(scene: Phaser.Scene, player: Player) {
    player.move();

    // ====================================================================
    // Transitions

    // transition to idle if not pressing movement keys
    if (checkIfMovementKeysNotPressed({ ...player.getKeys })) {
      this.stateMachine.transition(PlayerMovementStates.IDLE);
      return;
    }
    // transition to dash if pressing space
    if (checkIfDashing(player.getKeys.space, player)) {
      this.stateMachine.transition(PlayerMovementStates.DASH);
      return;
    }
  }

  exit(scene: Phaser.Scene, player: Player) {
    // console.log(" < exiting move...");
    player.emit("exit-move");
  }
}

/**
 * /////////////////////////////////////////////////////////////////////////////////////////////////////////
 *
 * Player Dash State
 *
 * Dash can transition to:
 *  - Idle
 *  - Move
 */
export class DashState implements State<Player> {
  stateMachine: StateMachine<Player>;

  constructor(stateMachine: StateMachine<Player>) {
    this.stateMachine = stateMachine;
  }

  enter(scene: Phaser.Scene, player: Player) {
    // console.log(" > entering dash...");

    player.emit("enter-dash");
    // const debugFlip = (player.flipX) ? "(flipped)" : "(not flipped)";
    // console.log(player.currentDirection);
    // console.log("PLAYING DASH ANIMATION: " + debugFlip + " dash_" + PlayerAnimationIndex[player.currentDirection]);

    // ====================================================================
    // Transitions

    // let start = performance.now();
    // const tempTimers: number[] = [];

    // after a delay, stop dashing and transition to either 'idle' or 'move' - the delay is based on the player variable 'dashDuration'
    scene.time.delayedCall(player.getDashDuration, () => {
      // console.log("dash finished");
      // console.timeEnd("timer");
      // const end = performance.now();
      // const duration = end - start;
      // tempTimers.push(duration);
      // const average = (arr: number[]) => arr.reduce((p, c) => p + c, 0) / arr.length;
      // console.log(average(tempTimers));
      // console.log(duration);
      // start = performance.now();

      if (checkIfMovementKeysPressed({ ...player.getKeys })) {
        this.stateMachine.transition(PlayerMovementStates.MOVE);
        return; // if moving, to move
      }

      this.stateMachine.transition(PlayerMovementStates.IDLE); // else always go to idle
    });
  }

  update(scene: Phaser.Scene, player: Player) {
    player.dash();
  }

  exit(scene: Phaser.Scene, player: Player) {
    // console.log(" < exiting dash...");
    player.emit("exit-dash");
    player.dashEnded();
  }
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////
// Transition Functions
/////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Check if movement keys are currently being pressed, and if so, transition to Move state
 */
function checkIfMovementKeysPressed(
  { movementKeys: { W, S, A, D } }: IKeys // use destructuring to make a local copy of the keyboard object
): boolean {
  return W.isDown || S.isDown || A.isDown || D.isDown ? true : false;
}

/**
 * Check if movement keys are not currently being pressed, and if so, transition to Idle state
 */
function checkIfMovementKeysNotPressed(
  { movementKeys: { W, S, A, D } }: IKeys // use destructuring to make a local copy of the keyboard object
): boolean {
  return !(W.isDown || S.isDown || A.isDown || D.isDown) ? true : false;
}

/**
 * Check if space is pressed, and if so, transition to the Dash state
 * also checks if the dash is off cooldown with `player.getDashAvailable`
 */
function checkIfDashing(space: Phaser.Input.Keyboard.Key, player: Player): boolean {
  if (Phaser.Input.Keyboard.JustDown(space)) {
    if (player.getDashAvailable) {
      return true;
    } else {
      console.warn("Dash on Cooldown!");
    }
  }
  return false;
}
