import Metronome from "../../metronome/Metronome";
import BaseGameSprite from "../base/BaseGameSprite";
import StateMachine, { PossibleStates } from "../../states/StateMachine";
import { ActionState, IdleState, ThinkState } from "./BossStates";
import { select } from "weighted-map";
import Constants from "../../Constants";
import AbilityGraph from "../../ability/AbilityGraph";
import BasePhysicsSprite from "../base/BasePhysicsSprite";
import { BaseAbility } from "../../ability/BaseAbility";
import { BossAbilityTeleport } from "./abils/standard/BossAbilityTeleport";
import { BossAbilityRegenerate } from "./abils/intrinsic/BossAbilityRegenerate";
import { BossAbilityNoteMissile } from "./abils/standard/BossAbilityNoteMissile";
import { BossAbilitySummonMinions } from "./abils/standard/BossAbilitySummonMinions";

export enum BossStates {
  IDLE = "IDLE",
  THINK = "THINK",
  ACTION = "ACTION",
}

// Update interfaces as we add more abilities ...
interface BossAbilitiesAvailable {
  teleport: BaseAbility;
  summonMinions: BaseAbility;
  noteMissile: BaseAbility;
}

interface BossAbilitiesHidden {
  regen: BaseAbility;
}

export default class Boss extends BaseGameSprite {
  private _bossFSM: StateMachine<Boss>;
  private _abilityGraph: AbilityGraph<Boss>; // responsible for constructing & executing multiple abilities
  private _bossAbilitiesAvailable: BossAbilitiesAvailable; // abilities AVAILABLE - there's a difference between available and selected abilities
  private _bossAbilitiesHidden: BossAbilitiesHidden; // any boss abilities that are not be chosen, but can be used at certain points, i.e. regeneration

  private _minRespirationCost: number; // during this boss phase, the minimum ability cost the boss can choose from
  private _maxRespirationCost: number; // during this boss phase, the maximum ability cost the boss can choose from

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    metronome: Metronome,
    name: string,
    minRespiration: number,
    maxRespiration: number,
    // optional
    collisionSprites: BasePhysicsSprite[] = []
  ) {
    super(
      scene,
      x,
      y,
      "high-priest",
      metronome,
      name,
      100,
      minRespiration,
      maxRespiration,
      maxRespiration,
      collisionSprites
    );

    // set this values initially to the overall Boss respiration values, as a fall back incase the "setMinMaxRespirationCost()" fails
    // -> MIN: always allow the boss to do something, as long as there is ability with that minimum cost - technically will stall if no ability matches minimum - if 0, there can be a chance the Boss does NOTHING
    // -> MAX: must be a value <= (resourceValueMax - resourceValueMin)
    this._minRespirationCost = minRespiration;
    this._maxRespirationCost = maxRespiration;

    // initialise Boss state machine
    this._bossFSM = new StateMachine<Boss>(scene, this);

    const initBossStates: PossibleStates<Boss> = {
      [BossStates.IDLE]: new IdleState(this._bossFSM),
      [BossStates.THINK]: new ThinkState(this._bossFSM),
      [BossStates.ACTION]: new ActionState(this._bossFSM),
    };
    this._bossFSM.addStates(initBossStates);
    this._bossFSM.initialiseState(BossStates.IDLE);

    this.setImmovable(true);
    this.setScale(2); // TODO

    // initialise health bar image (for demo purposes) - TODO remove
    scene.add
      .image(0, Constants.GAME_HEIGHT - 200, "boss_hp")
      .setOrigin(0)
      .setDepth(1000)
      .setScale(0.4);

    // initialise boss abilities with cost / weights
    this._bossAbilitiesAvailable = {
      teleport: new BossAbilityTeleport(this),
      summonMinions: new BossAbilitySummonMinions(this),
      noteMissile: new BossAbilityNoteMissile(this),
      // ...
    };

    this._bossAbilitiesHidden = {
      regen: new BossAbilityRegenerate(this), // regen amount is dynamically chosen
    };

    // ability graph - this will be used in ACTION boss state to call each ability in order
    this._abilityGraph = new AbilityGraph(this, [this._bossAbilitiesAvailable.noteMissile]);

    this.setMinMaxRespirationCost(15, 20);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  // boss state machine

  public get bossFSM(): StateMachine<Boss> {
    return this._bossFSM;
  }

  // minimum respiration cost (ability graph deals with setter)

  public get minRespirationCost(): number {
    return this._minRespirationCost;
  }

  // maximum respiration cost (ability graph deals with setter)

  public get maxRespirationCost(): number {
    return this._maxRespirationCost;
  }

  // ability graph

  public get abilityGraph(): AbilityGraph<Boss> {
    return this._abilityGraph;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Other
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Allows you to safely set the Min and Max COST values on the Boss, i.e. the range of abilities the boss can choose from, based on cost
   * @param min
   * @param max
   */
  public setMinMaxRespirationCost(min: number, max: number) {
    // min / max constraints - considering if we throw errors or just log them? we don't want the flow of the game to stop
    if (min < 0 || max < 0) {
      console.error("Min / Max Cost cannot be negative! VALUES NOT SET");
      return;
      // throw new Error("Min / Max Cost cannot be negative!");
    }
    if (max > this.resourceValueMax - this.resourceValueMin) {
      console.error("Max Cost value greater than Boss Max Respiration Bar! VALUES NOT SET");
      return;
      // throw new Error("Max Cost value greater than Boss Max Respiration Bar!");
    }

    if (min > max) {
      console.error("Min Cost value greater than Max Cost value! VALUES NOT SET");
      return;
      // throw new Error("Min Cost value greater than Max Cost value!");
    }
    this._minRespirationCost = min;
    this._maxRespirationCost = max;
  }

  /**
   * This will be the randomly selected value between the min + max (and some other conditions later on), which
   * determines the cost of abilities to use / chain during this iteration
   * @returns FALSE if the calculated cost was successful with no range issues - TRUE if the min cost range is out of bounds, meaning boss should regen
   */
  private calculateInitialStep(): boolean {
    let value = 0;
    let tempMaxCost = this._maxRespirationCost;

    // if the curr respiration is BELOW max cost, but still ABOVE min cost, then just adjust max cost range to be curr respiration
    if (
      this.resourceValueCurr < this._maxRespirationCost &&
      this.resourceValueCurr >= this._minRespirationCost
    ) {
      // console.warn(`max cost: ${this._maxRespirationCost} ==> ${this._currentRespirationBarValue}`);
      tempMaxCost = this.resourceValueCurr;
    } else if (this.resourceValueCurr < this._minRespirationCost) {
      // else if the curr respiration is BELOW min cost, need to regen no matter what
      // console.log("current respiration", this._currentRespirationBarValue);
      if (Constants.ENABLE_BOSS_LOGS) console.warn("BOSS NEEDS TO REGEN");
      return true;
    }

    value = Phaser.Math.Between(this._minRespirationCost, tempMaxCost);
    this._abilityGraph.startingCost = value;
    this._abilityGraph.remainingCost = value;

    if (Constants.ENABLE_BOSS_LOGS) {
      console.log("range: (", this._minRespirationCost, ",", tempMaxCost, ")");
      console.log("chosen cost this iteration: ", value);
    }

    // console.log(
    //   JSON.stringify(
    //     {
    //       // abilityGraph: this._abilityGraph.getAllValues(),
    //       remainingCostValue: this._abilityGraph.remainingCost,
    //       minRespirationCost: this._minRespirationCost,
    //       maxRespirationCost: this._maxRespirationCost,
    //       minRespirationBarValue: this.resourceValueMin,
    //       maxRespirationBarValue: this.resourceValueMax,
    //       currentRespirationBarValue: this.resourceValueCurr,
    //     },
    //     null,
    //     2
    //   )
    // );

    return false;
  }

  /**
   * Boss Thinking Logic
   * This is where the construction happens on what abilities to pick for the boss, based on available / hidden abilities
   * We append abilities we want to execute to the ability graph, and then the State machine will be responsible for calling the execution method
   */
  public async thinkingLogic() {
    // TODO testing
    this._bossAbilitiesAvailable.noteMissile.initAbility();
    // console.warn(this._bossAbilitiesAvailable.noteMissile.cost);

    const shouldRegen = this.calculateInitialStep();

    // if shouldRegen is false, ignore thinking and just regen
    if (shouldRegen) {
      this._abilityGraph.addAbility(this._bossAbilitiesHidden.regen);
      return;
    }

    // laid out like this so its easier to understand the conditionals ...
    const validBossAbilitiesFilter = (v: BaseAbility) => {
      // if the cost is greater than remaining cost, then invalid ability
      if (Math.abs(v.cost) > this.abilityGraph.remainingCost) return false;

      // if this ability is part of "once per chain" logic - otherwise just ignore
      if (this.abilityGraph.abilitiesOncePerChain.some((o) => o === v)) {
        // if this ability has already shown up, then invalid ability
        if (this.abilityGraph.abilitiesToUse.some((o) => o === v)) return false;
      }
      return true;
    };

    // initially generate list of valid abilities to add - feels meh generating each time, but this way, we know when to stop the loop
    // ... or we introduce a "minimum ability cost value" globally, or have an ability that is cost of 1, that way it will always be able to reach 0
    let validBossAbilitiesAvailable = Object.values(this._bossAbilitiesAvailable).filter(
      validBossAbilitiesFilter
    );

    // BEGIN ASYNC RECURSIVE LOOP - this is so we can use "await" once the boss has completed thinking logic (adding abilities etc.)
    // technically we can do this with "emits", idk why I went with async, but whatever
    const loop = async () => {
      // console.log(
      //   `validBossAbilities under ${this._abilityGraph.remainingCost} cost`,
      //   JSON.stringify(
      //     validBossAbilitiesAvailable.map((v) => `${v.name} == ${v.cost}`),
      //     null,
      //     2
      //   )
      // );
      // console.log(
      //   "abilities chosen:",
      //   JSON.stringify(
      //     this.abilityGraph.abilitiesToUse.map((v) => `${v.name} == ${v.cost}`),
      //     null,
      //     2
      //   )
      // );

      // exit the loop if there are no more valid abilities
      if (validBossAbilitiesAvailable.length === 0) return;

      // first get it in the correct format - possibly can improve this=
      const weightedMapOfValidBossAbilities = new Map<BaseAbility, number>();
      Object.values(validBossAbilitiesAvailable).forEach((v: BaseAbility) => {
        weightedMapOfValidBossAbilities.set(v, v.weight);
      });

      // using select from 'weighted-map', we can randomly choose abilities based on the probability weight set within the ability itself - append to ability graph
      const selectedAbility = select(weightedMapOfValidBossAbilities);
      // console.error(selectedAbility);
      this._abilityGraph.addAbility(selectedAbility);

      // at the end of the loop, recalculate valid abilities to use
      validBossAbilitiesAvailable = Object.values(this._bossAbilitiesAvailable).filter(
        validBossAbilitiesFilter
      );

      const loopSpeed = 10; // limit - the lower the value, faster it will loop - if feeling like we are lagging, can increase this number, although loop will be slower
      await new Promise((resolve) => setTimeout(resolve, loopSpeed)); // wait for a short time before continuing the loop so it doesn't lag the game out
      await loop();
    };

    await loop();

    // console.log("cost used", this.abilityGraph.startingCost - this.abilityGraph.remainingCost);
    // console.log("current respiration", this.resourceValueCurr);
    // console.log(
    //   "new current respiration",
    //   this.resourceValueCurr - (this.abilityGraph.startingCost - this.abilityGraph.remainingCost)
    // );
    // console.log(JSON.stringify(this._abilityGraph.getAllValues(), null, 2));
    // console.log("starting cost", this.abilityGraph.startingCost);
    // console.log("remaining cost", this.abilityGraph.remainingCost);
  }

  update(time: number) {
    // FSM update
    this._bossFSM.step(time);

    // console.error(
    //   Phaser.Math.Between(this.minRespirationCost, this.maxRespirationCost) -
    //     (this.minRespirationCost - 1)
    // );
  }
}

/**
 * Example of Cost Range Setups (with current respiration bar value of 100 for example - and minimum of 10):
 *
 * By default, negative values are forbidden
 *
 * 10 - 90:
 *  • boss can select min cost ability of 10, meaning boss will have bar value of 90 after action resolution
 *  • boss can select max cost ability of 90, meaning boss will have bar value of 10 after action resolution (i.e. minimum)
 *  • This setup allows the boss never to go to 0 respiration, there will always be 10 cost remaining
 *  • Also allows access to much more expensive abilities, i.e. maybe there is ability of cost 70, which other cost ranges might not offer (max being 90 in this case)
 *
 * 10 - 50:
 *  • ... select min cost ability of 10 ... bar value of 90 after resolution
 *  • ... select max cost ability of 50 ... bar value of 50 after resolution
 *  • Doesn't allow access to abilities with a cost greater than 50 - i.e. limited (weak) ability range
 *
 * 50 - 90:
 *  • ... select min cost ability of 50 ... bar value of 50 after resolution
 *  • ... select max cost ability of 90 ... bar value of 10 after resolution
 *  • Same as 10-90, but with no chance of using cost abilities less than 50 - i.e. more of an aggressive ability range
 *
 * 0 - 90:
 *  • ... select min cost ability of 0 ... bar value of 100 after resolution
 *  • ... select max cost ability of 90 ... bar value of 10 after resolution
 *  • Same as 10-90, but with some chance of boss not doing anything - again, sort of a weaker ability range
 *
 * Invalid Ranges:
 *
 * 60 - 50:
 *  • This range should also NOT be allowed because the minimum cost cannot be greater than the maximum cost
 *  • constraint: MIN ability range value must be <= MAX ability range value
 *
 */
