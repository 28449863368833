import Constants from "../Constants";
import BaseGameSprite from "../sprites/base/BaseGameSprite";
import BasePhysicsSprite from "../sprites/base/BasePhysicsSprite";
import { BaseAbility } from "./BaseAbility";

/**
 * Ability Graph which handles the construction and execution of multiple abilities
 *
 * Think of this as a list of chained abilities essentially:
 *    abilitiesToUse == [Teleport --> Summon Minions --> Some Attack --> Teleport]
 *
 * Once executeAbilitiesToUse() is called, it will iterate through this list and execute each ability on time with the metronome
 *
 * To note: any type of (physics) sprite can use an ability graph, even things that don't use a resource (mana)
 */
export default class AbilityGraph<T extends BasePhysicsSprite> {
  // the actual sprite - Boss, Player etc.
  private _sprite: T;

  private _startingCost = 0; // for validations / logging
  private _remainingCost = 0; // for validations / logging

  private _abilitiesToUse: BaseAbility[] = [];
  private _currentExecutedAbility: BaseAbility | null = null;
  private _abilitiesOncePerChain: BaseAbility[] = []; // abilities allowed only once per chain / iteration

  constructor(sprite: T, abilitiesOncePerChain: BaseAbility[]) {
    this._sprite = sprite;
    this._abilitiesOncePerChain = abilitiesOncePerChain;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Getters + Setters
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  // remaining cost

  public get startingCost(): number {
    return this._startingCost;
  }

  public set startingCost(v: number) {
    this._startingCost = v;
  }

  // remaining cost

  public get remainingCost(): number {
    return this._remainingCost;
  }

  public set remainingCost(v: number) {
    this._remainingCost = v;
  }

  // abilities to use

  public get abilitiesToUse(): BaseAbility[] {
    return this._abilitiesToUse;
  }

  // current ability

  public get currentExecutedAbility(): BaseAbility | null {
    return this._currentExecutedAbility;
  }

  // abilities once per chain

  public get abilitiesOncePerChain(): BaseAbility[] {
    return this._abilitiesOncePerChain;
  }

  /**
   * Executes the abilities ... TODO
   * Using Recursion ;)
   * @param currentAbilityIndex the index to start from, should be 0 initially
   */
  public executeAbilitiesToUse(currentAbilityIndex = 0) {
    if (Constants.ENABLE_ABILITY_LOGS)
      console.warn(
        `${this._abilitiesToUse.length} abilities to use, ${currentAbilityIndex + 1} current index`
      );
    if (currentAbilityIndex >= this._abilitiesToUse.length) {
      this.purgeAbilityGraph();
      return;
    }

    const getAbility = this._abilitiesToUse[currentAbilityIndex];

    // ability "once" event for action complete
    getAbility.once(`${getAbility.name}-action-complete`, () => {
      if (Constants.ENABLE_ABILITY_LOGS) console.warn(`${getAbility.name} COMPLETE`);
      if (Constants.ENABLE_ABILITY_LOGS) console.warn("----------------------------------------");
      getAbility.off(Constants.ABILITY_ACTIONED); // remove ability actioned event
      currentAbilityIndex++;
      this.executeAbilitiesToUse(currentAbilityIndex); // recursively call itself again until no abilities are left to be executed
    });
    this._currentExecutedAbility = getAbility;

    // while executing ability, create a listening event for the ability for additional logic, i.e. deducting cost - the Ability class shouldn't have this logic because it should only worry about execution
    // we need to make this "on" rather than "once" for channel-type abilities, where the resource is deducted over time
    getAbility.on(Constants.ABILITY_ACTIONED, (cost: number) => {
      if (this._sprite instanceof BaseGameSprite) this._sprite.adjustResource(cost); // if the sprite is of type Game Sprite, then run cost logic
    });
    getAbility.executeAbility();

    if (Constants.ENABLE_ABILITY_LOGS)
      console.warn(
        `${getAbility.name} executed, waiting on metronome: ${getAbility.metronomeInterval.windup}`
      );
  }

  /**
   * Adds an ability to this ability graph (i.e. array) - remembering this ABILITY only persists for a THINK -> ACTION iteration
   * Once all abilities have been executed through this ability graph, the list is cleaned ready for the next iteration
   *
   * If the ability's cost we are trying to add exceeds the remaining cost (i.e. not enough mana), then do not add ability
   * We shouldn't reach this state anyway, since things like the `validBossAbilities` array within the `thinkingLogic` already solves this, but kept as a precaution
   * @param ability ability to add to the array
   */
  public addAbility(ability: BaseAbility) {
    if (this._remainingCost - Math.abs(ability.cost) < 0) {
      if (Constants.ENABLE_ABILITY_LOGS)
        console.warn(
          `Unable to add Ability to Ability Graph for ${this._sprite} - not enough cost`
        ); // technically shouldn't get here, as we will generate valid list of abilities to add anyway
      return false;
    }
    // add to abilities array as long as the ability's cost does not bring the remaining cost below 0 (i.e. not enough respiration to cast ability)
    this._abilitiesToUse.push(ability);
    this._remainingCost -= Math.abs(ability.cost); // subtract cost from ability graph remaining cost
    return true;
  }

  /**
   * Clears the ability list, e.g. to be ready for next iteration of abilities
   */
  public purgeAbilityGraph() {
    this._abilitiesToUse = [];
    this._currentExecutedAbility = null; // clear abilities once complete
    this._sprite.emit(Constants.SPRITE_NO_MORE_ABILITIES);
  }

  /**
   * Retrieves all data within this class and returns it to be console logged etc.
   * @returns object of all necessary data fields within this class
   */
  public getAllValues() {
    return {
      // remainingCost: this._remainingCost,
      abilitiesToUse: this._abilitiesToUse.map((v) => v.name),
    };
  }
}
