import { isPhysicsBody } from "../../helpers/Helpers";
import Heart, { HeartbeatDirection } from "./Heart";
import Heartbeat from "./Heartbeat";

export interface Emitter {
  zone: Phaser.GameObjects.GameObject;
  heartbeat: Heartbeat;
}

/**
 * A collective group of heartbeats to be created at the start of the game, and be used later on
 */
export default class Heartbeats extends Phaser.Physics.Arcade.Group {
  private heart: Heart;
  private collisionListGood: Heartbeat[] = [];
  private collisionListGreat: Heartbeat[] = [];

  constructor(
    scene: Phaser.Scene,
    heart: Heart,
    amountOfHeartBeats: number,
    velocity: number,
    direction: HeartbeatDirection
  ) {
    super(scene.physics.world, scene);

    this.heart = heart;

    // create N amount of heartbeat instances and add to the group (fancy way of writing a for i loop) - doing it this way rather than "createMultiple" so we can call our constructor and feed through values we want
    [...Array(amountOfHeartBeats)].forEach((_, i) => {
      const heartbeat = new Heartbeat(
        scene,
        -100,
        -100,
        "q1",
        this.heart,
        velocity,
        direction,
        `hb${i}`
      ); // x and y off the screen, don't think it makes a difference anyway
      heartbeat.setScale(this.heart.scale * (1 / 3)); // scale it based on heart
      heartbeat.active = false;
      heartbeat.visible = false;
      heartbeat.setDepth(this.heart.depth + 1);

      // technically the RIGHT heartbeats are also being initialised with this event, which is a waste - TODO
      // listen to the event 'collisionGood' - runs when a heartbeat has collided with good zone
      heartbeat.on("collisionGood", (heartbeat: Heartbeat) => {
        // console.log("================================================================");
        // console.log("COLLISION GOOD", heartbeat.name);

        heartbeat.setHasCollidedGood = true;
        this.collisionListGood.push(heartbeat);

        // console.log("GOOD:", this.collisionListGood.map(e => e.name));
      });

      // listen to the event 'collisionGood' - runs when a heartbeat has collided with great zone
      heartbeat.on("collisionGreat", (heartbeat: Heartbeat) => {
        // console.log("================================================================");
        // console.log("COLLISION GREAT", heartbeat.name);

        heartbeat.setHasCollidedGreat = true;
        this.collisionListGreat.push(heartbeat);

        const heartbeatIndexToRemove = this.collisionListGood.findIndex(
          (e) => e.name === heartbeat.name
        );
        if (heartbeatIndexToRemove > -1) {
          // console.log(`remove ${heartbeat.name} from GOOD`);
          this.collisionListGood.splice(heartbeatIndexToRemove, 1);
        }

        // console.log("GOOD:", this.collisionListGood.map(e => e.name));
        // console.log("GREAT:", this.collisionListGreat.map(e => e.name));
      });

      heartbeat.on("noInputDetected", () => {
        // scene.cameras.main.shake(100, 0.005);
        const heartbeat = this.collisionListGreat.shift();
        if (!heartbeat) return;
        heartbeat.resetBody(true);
      });

      heartbeat.on("hitDetected", (emitter: Emitter) => {
        // console.log("HIT DETECTED", {
        // 	heartbeat: emitter.heartbeat.name,
        // 	zone: emitter.zone.name
        // });

        if (isPhysicsBody(emitter.zone.body)) {
          const zoneX = emitter.zone.body.x + emitter.zone.body.width / 2;
          const zoneY = emitter.zone.body.y + 125; // -50 for heart on bottom of screen, 125 for heart on top
          const debugText = this.scene.add
            .text(zoneX, zoneY, emitter.zone.name)
            .setOrigin(0.5, 1)
            .setDepth(this.heart.depth)
            .setScale(1.5);
          this.scene.add.tween({
            targets: debugText,
            x: zoneX,
            y: zoneY + 50,
            alpha: 0,
            duration: 1500,
            ease: "Power1",
            onComplete: () => {
              debugText.destroy();
            },
          });
        }
      });

      this.add(heartbeat);
    });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  public get getCollisionListGood(): Heartbeat[] {
    return this.collisionListGood;
  }

  public get getCollisionListGreat(): Heartbeat[] {
    return this.collisionListGreat;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Setters
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  public set setHeartbeatVelocityX(newVelocityX: number) {
    this.getChildren().forEach((obj) => {
      const heartbeat = obj as Heartbeat;
      heartbeat.setHeartbeatVelocityX = newVelocityX;
    });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Functions
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  // public update(time: number) {
  // console.log(this.heart.getHeartOverlapZoneGoodBody);
  // console.log(this.collisionListGood.map(e => e.name));
  // console.log(this.name, ":", scene.physics.world.overlap(this, this.heart.getHeartOverlapZoneGood));
  // }

  public initialiseCollisionWithHeart() {
    // print out collision array for debugging every 500 ms
    // scene.time.addEvent({
    // 	callback: () => {
    // 		console.log("GOOD:", this.collisionListGood.map(e => e.name));
    // 		console.log("GREAT:", this.collisionListGreat.map(e => e.name));
    // 	},
    // 	callbackScope: this,
    // 	delay: 500,
    // 	loop: true
    // });

    this.scene.physics.add.overlap(this, this.heart.getHeartOverlapZoneGood, (_, obj) => {
      const heartbeat = obj as Heartbeat;
      if (
        !this.collisionListGood.some((e) => e.name === heartbeat.name) &&
        !heartbeat.getHasCollidedGood
      ) {
        heartbeat.emit("collisionGood", heartbeat);
      }
    });

    this.scene.physics.add.overlap(this, this.heart.getHeartOverlapZoneGreat, (_, obj) => {
      const heartbeat = obj as Heartbeat;
      if (
        !this.collisionListGreat.some((e) => e.name === heartbeat.name) &&
        !heartbeat.getHasCollidedGreat
      ) {
        heartbeat.emit("collisionGreat", heartbeat);
      }
    });
  }

  public toggleHeartbeatsDebug(bool: boolean) {
    this.getChildren().forEach((obj) => {
      const heartbeat = obj as Heartbeat;
      heartbeat.toggleHeartbeatDebug(bool);
    });
  }

  public fireHeartbeat() {
    const heartbeat = this.getFirstDead(false) as Heartbeat;
    if (heartbeat) {
      heartbeat.fire();
    }
  }
}
