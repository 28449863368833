import BasePhysicsSprite from "../base/BasePhysicsSprite";
import BaseProjectile from "../base/BaseProjectile";
import Constants, { AnimationDirectionIndexMap, DirectionType } from "../../Constants";
import StateMachine, { PossibleStates } from "../../states/StateMachine";
import {
  DashState as M_DashState,
  IdleState as M_IdleState,
  MoveState as M_MoveState,
} from "./PlayerMoveStates";
import { IdleState as C_IdleState, AttackState as C_AttackState } from "./PlayerAttackStates";
import Metronome from "../../metronome/Metronome";
import { isEmpty, xor } from "lodash";
import BaseGameSprite from "../base/BaseGameSprite";

export enum PlayerMovementStates {
  IDLE = "IDLE",
  MOVE = "MOVE",
  DASH = "DASH",
}

export enum PlayerCombatStates {
  IDLE = "IDLE",
  ATTACK = "ATTACK",
}

export const PlayerAnimationIndex: AnimationDirectionIndexMap = {
  UP: { key: "back", angle: -1 }, // opposites
  DOWN: { key: "front", angle: -1 },
  LEFT: { key: "right", angle: -1 }, // notice here how LEFT is assigned "right" - the sprite will be flipped when walking to the left, saving animation space
  RIGHT: { key: "right", angle: -1 },
  // diagonal movement eventually
  UP_LEFT: { key: "right", angle: -1 },
  UP_RIGHT: { key: "right", angle: -1 },
  DOWN_LEFT: { key: "right", angle: -1 },
  DOWN_RIGHT: { key: "right", angle: -1 },
  IDLE: { key: "front", angle: -1 }, // if not walking, idle animation plays, which will be "front" in this case
};

export const enum MovementKeys {
  W = "W",
  S = "S",
  A = "A",
  D = "D",
}

export interface IKeys {
  movementKeys: {
    [key: string]: Phaser.Input.Keyboard.Key; // ideally would be [key in MovementKeys] but this does not allow for string indexing (for Object.keys(this.keys.movementKeys) below)
  };
  space: Phaser.Input.Keyboard.Key;
  shift: Phaser.Input.Keyboard.Key;
}

export default class Player extends BaseGameSprite {
  // private gamepad!: Phaser.Input.Gamepad.Gamepad | null;

  private keys: IKeys;
  private movementStack: Phaser.Input.Keyboard.Key[] = [];
  private invalidKeyPairs: number[][] = [];

  private playerMovementFSM: StateMachine<Player>;
  private playerCombatFSM: StateMachine<Player>; // two FSM since they are independent

  private attackDuration: number;
  private dashDuration: number;
  private dashCooldown: number;
  private dashAvailable: boolean;

  currentDirection: DirectionType;

  private playerSpeed: number;
  private playerDashSpeed: number;

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    metronome: Metronome,
    name: string,
    // optional
    collisionSprites: BasePhysicsSprite[] = []
  ) {
    super(scene, x, y, "temp_sprite", metronome, name, 100, 0, 0, 0, collisionSprites);

    this.keys = {
      movementKeys: {
        [MovementKeys.W]: this.scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.W),
        [MovementKeys.S]: this.scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.S),
        [MovementKeys.A]: this.scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.A),
        [MovementKeys.D]: this.scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.D),
      },
      space: this.scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.SPACE),
      shift: this.scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.SHIFT),
      // HKey: this.scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.H), // demo hurt state
    };

    this.attackDuration = Constants.PLAYER_ATTACK_DURATION_MS;

    this.dashDuration = Constants.PLAYER_DASH_DURATION_MS;
    this.dashCooldown = Constants.PLAYER_DASH_COOLDOWN_MS;
    this.dashAvailable = true;

    this.currentDirection = DirectionType.RIGHT;
    this.playerSpeed = Constants.PLAYER_SPEED;
    this.playerDashSpeed = Constants.PLAYER_DASH_SPEED;

    // Player Movement FSM - based on PlayerMoveStates
    this.playerMovementFSM = new StateMachine<Player>(scene, this);
    const initPlayerMoveStates: PossibleStates<Player> = {
      [PlayerMovementStates.IDLE]: new M_IdleState(this.playerMovementFSM),
      [PlayerMovementStates.MOVE]: new M_MoveState(this.playerMovementFSM),
      [PlayerMovementStates.DASH]: new M_DashState(this.playerMovementFSM),
      // ...
    };
    this.playerMovementFSM.addStates(initPlayerMoveStates); // add the states to the state machine
    this.playerMovementFSM.initialiseState(PlayerMovementStates.IDLE); // then initialise a starting state, i.e. IDLE

    // Player Combat FSM - based on PlayerAttackStates
    this.playerCombatFSM = new StateMachine<Player>(scene, this);
    const initPlayerCombatStates: PossibleStates<Player> = {
      [PlayerCombatStates.IDLE]: new C_IdleState(this.playerMovementFSM),
      [PlayerCombatStates.ATTACK]: new C_AttackState(this.playerCombatFSM),
      // ...
    };
    this.playerCombatFSM.addStates(initPlayerCombatStates);
    this.playerCombatFSM.initialiseState(PlayerCombatStates.IDLE);

    // console.warn(this.playerMovementFSM.getPossibleStates);
    // console.warn(this.playerCombatFSM.getPossibleStates);

    // do idle animation here
    this.setDepth(Constants.PLAYER_Z); // some large number
    this.setScale(2);
    this.setSize(20, 20); // change hit box size
    // this.setOrigin(0.5, 0.5);

    this.scene.input.on("pointerdown", (p: Phaser.Input.Pointer) => {
      this.emit("playerPointerDown", p);
      // console.warn("playerPointerDown");
    });

    // create stack-based player movement keyboard inputs - for each key within movementKeys object
    Object.keys(this.keys.movementKeys).forEach((key: string) => {
      console.debug("Creating player movement keyboard input for:", key);
      this.scene.input.keyboard.on(`keydown-${key}`, () => {
        // console.log(key, "down");
        this.movementStack.unshift(this.keys.movementKeys[key]);
      });
      this.scene.input.keyboard.on(`keyup-${key}`, () => {
        // console.log(key, "up");
        this.movementStack = this.movementStack.filter(
          (v: Phaser.Input.Keyboard.Key) => v.keyCode !== this.keys.movementKeys[key].keyCode
        );
      });
    });

    // easier to write the invalid pairs - i.e. opposite movements
    this.invalidKeyPairs = [
      [Phaser.Input.Keyboard.KeyCodes.W, Phaser.Input.Keyboard.KeyCodes.S], // W == 87, S == 83
      [Phaser.Input.Keyboard.KeyCodes.A, Phaser.Input.Keyboard.KeyCodes.D], // A == 65, D == 68
    ];

    // player initially is facing left, so flip right - this will mean the sprite flipX value is true until flipped again - i.e. FALSE = RIGHT, TRUE = LEFT
    // this.flip();

    // scene.input.gamepad.once("connected", (pad: Phaser.Input.Gamepad.Gamepad | null) => {
    // 	//   'pad' is a reference to the gamepad that was just connected

    // 	console.log("connected");
    // 	this.gamepad = pad;

    // });

    // controller support here
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  public get getPlayerSpeed(): number {
    return this.playerSpeed;
  }

  public get getAttackDuration(): number {
    return this.attackDuration;
  }

  public get getDashDuration(): number {
    return this.dashDuration;
  }

  public get getDashCooldown(): number {
    return this.dashCooldown;
  }

  public get getDashAvailable(): boolean {
    return this.dashAvailable;
  }

  public get getCurrentDirection(): DirectionType {
    return this.currentDirection;
  }

  public get getPlayerMovementFSM(): StateMachine<Player> {
    return this.playerMovementFSM;
  }

  public get getPlayerCombatFSM(): StateMachine<Player> {
    return this.playerCombatFSM;
  }

  public get getKeys(): IKeys {
    return this.keys;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Setters
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  public setPlayerMoveState(direction: DirectionType) {
    this.currentDirection = direction;
  }

  // public setPlayerAttackState(state:);

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Functions
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  update(time: number) {
    // FSM update
    this.playerMovementFSM.step(time);
    this.playerCombatFSM.step(time);
  }

  /**
   * Move the player
   */
  public move() {
    if (isEmpty(this.movementStack)) return; // ensure movementStack is not empty when calculating movement - shouldn't be anyway inside `move()`
    if (this.body.velocity.length() !== 0) this.setVelocity(0); // set velocity to 0, if it isn't already

    // get latest movement from the stack, e.g.
    // press A (left), then W (up), then D (right) ==> D is latest key press, move RIGHT
    const latestMovement = this.movementStack[0];
    const pairMovementStack = this.movementStack.slice(1);

    // console.log(
    //   "this.movementStack",
    //   this.movementStack.map((v) => String.fromCharCode(v.keyCode))
    // );

    // calculate valid pair, if pressing second key, e.g.
    // press A (left), then W (up), then D (right)  ==> D and W is valid pair, so movement is UP RIGHT
    // press A (left), then W (up), then S (down)   ==> S and W is NOT a valid pair, so fall back to A, therefore movement is DOWN LEFT
    const index = pairMovementStack.findIndex((key) => {
      const pair = [latestMovement.keyCode, key.keyCode];
      // console.log(
      //   "construct pair (latest):",
      //   [String.fromCharCode(latestMovement.keyCode)],
      //   "with (keys on stack)",
      //   pairMovementStack.map((v) => String.fromCharCode(v.keyCode))
      // );
      // console.log(
      //   "pair",
      //   pair.map((v) => String.fromCharCode(v))
      // );

      const isInvalidPair = this.invalidKeyPairs.some((ip) => {
        // console.log(
        //   "compare against invalid:",
        //   ip.map((v) => String.fromCharCode(v))
        // );
        // console.log("is it invalid?", xor(pair, ip).length === 0);
        return xor(pair, ip).length === 0;
      });

      return !isInvalidPair; // return only valid pairs
    });

    // based on the valid pair calculation, we are able to find the latest valid direction to move in, based on player key WASD key press
    const secondaryLatestValidMovement = pairMovementStack[index];

    // log the movement pair
    // console.log({
    //   latest_key: latestMovement.originalEvent.key,
    //   with_pair: secondaryLatestValidMovement?.originalEvent?.key,
    // });

    if (
      latestMovement.keyCode === this.keys.movementKeys[MovementKeys.W].keyCode ||
      secondaryLatestValidMovement?.keyCode === this.keys.movementKeys[MovementKeys.W].keyCode
    ) {
      this.currentDirection = DirectionType.UP;
      this.setVelocityY(-this.getPlayerSpeed);
    }

    if (
      latestMovement.keyCode === this.keys.movementKeys[MovementKeys.S].keyCode ||
      secondaryLatestValidMovement?.keyCode === this.keys.movementKeys[MovementKeys.S].keyCode
    ) {
      this.currentDirection = DirectionType.DOWN;
      this.setVelocityY(this.getPlayerSpeed);
    }

    if (
      latestMovement.keyCode === this.keys.movementKeys[MovementKeys.A].keyCode ||
      secondaryLatestValidMovement?.keyCode === this.keys.movementKeys[MovementKeys.A].keyCode
    ) {
      this.currentDirection = DirectionType.LEFT;
      this.setVelocityX(-this.getPlayerSpeed);
    }

    if (
      latestMovement.keyCode === this.keys.movementKeys[MovementKeys.D].keyCode ||
      secondaryLatestValidMovement?.keyCode === this.keys.movementKeys[MovementKeys.D].keyCode
    ) {
      this.currentDirection = DirectionType.RIGHT;
      this.setVelocityX(this.getPlayerSpeed);
    }

    // handle diagonal animations
    if (this.body.velocity.x < 0 && this.body.velocity.y < 0) {
      this.currentDirection = DirectionType.UP_LEFT;
      // console.log("up_left", this.currentDirection);
    }
    if (this.body.velocity.x > 0 && this.body.velocity.y < 0) {
      this.currentDirection = DirectionType.UP_RIGHT;
      // console.log("up_right", this.currentDirection);
    }
    if (this.body.velocity.x < 0 && this.body.velocity.y > 0) {
      this.currentDirection = DirectionType.DOWN_LEFT;
      // console.log("down_left", this.currentDirection);
    }
    if (this.body.velocity.x > 0 && this.body.velocity.y > 0) {
      this.currentDirection = DirectionType.DOWN_RIGHT;
      // console.log("down_right", this.currentDirection);
    }

    this.body.velocity.normalize().scale(this.getPlayerSpeed);

    this.walkingAnimation();
  }

  /**
   * Plays a walking animation - determined by the current direction the player is walking in with "PlayerAnimationIndex[this.currentDirection]"
   * Since the sprite (will eventually have) only one left or right direction animation, we flip the sprite when facing LEFT, and flip back when facing RIGHT
   * Saves time when only animating in one left / right direction, since we can flip all sprite animations within the code
   * TODO - depending on how we set up the ACTUAL animations, this logic might need to change
   */
  public walkingAnimation() {
    // if idle, return
    if (this.currentDirection === DirectionType.IDLE) return;

    // default sprite animation is RIGHT, so if LEFT, flip X axis
    if (
      (this.currentDirection === DirectionType.LEFT ||
        this.currentDirection === DirectionType.UP_LEFT ||
        this.currentDirection === DirectionType.DOWN_LEFT) &&
      !this.flipX
    ) {
      // console.log("flip left");
      this.setFlipX(true);
    }
    // then flip back to normal (RIGHT) if it has been flipped (walking LEFT)
    if (
      (this.currentDirection === DirectionType.RIGHT ||
        this.currentDirection === DirectionType.UP_RIGHT ||
        this.currentDirection === DirectionType.DOWN_RIGHT) &&
      this.flipX
    ) {
      // console.log("flip right");
      this.setFlipX(false);
    }

    // console.log("play anims: ", this.currentDirection);
    this.playGenericAnimation(PlayerAnimationIndex[this.currentDirection].key, -1, 24, true);
  }

  /**
   * .................
   * @param time
   */
  public dash() {
    this.dashAvailable = false;
    this.body.velocity.normalize().scale(this.playerDashSpeed);
    this.playGenericAnimation("dash_" + PlayerAnimationIndex[this.currentDirection].key, -1);
    this.setBlendMode(Phaser.BlendModes.ADD);
  }

  public dashEnded() {
    this.body.velocity.normalize().scale(this.playerSpeed);
    this.setBlendMode(Phaser.BlendModes.NORMAL);
    this.playGenericAnimation(PlayerAnimationIndex[this.getCurrentDirection].key);

    this.scene.time.delayedCall(this.getDashCooldown, () => {
      this.dashAvailable = true;
      // console.log("dash-ready");
      this.emit("dash-ready");
    });
  }

  public attack(p: Phaser.Input.Pointer) {
    // const temp = new BaseProjectile(
    //   this.scene,
    //   p.x,
    //   p.y,
    //   "organProjectile",
    //   this.metronome,
    //   "player_test_projectile",
    //   0, // TODO projectile speed
    //   undefined,
    //   this.collisionSprites // TODO probably a good idea to have initProjectileCollision or something, because sprite collision might be different to projectile collision
    // ).setDepth(1000);
    // temp.fireToPoint(this.x, this.y, p.x, p.y, 300);
    // cleanly destroy projectile if out of `bo`unds
    // temp.on("projectileOutOfBounds", (o: BaseProjectile) => o.kill());
  }
}
