import Constants from "../Constants";
import Player from "../sprites/player/Player";

export default class UIScene extends Phaser.Scene {
  private player!: Player;
  private cooldownTween: Phaser.Tweens.Tween | null;

  constructor() {
    super(Constants.UI_SCENE_KEY);
    this.cooldownTween = null;
  }

  init(data: any) {
    this.player = data.player;
  }

  create() {
    //

    //

    //

    //

    //

    //

    // example cooldown UI

    const dashImage = this.add
      .image(Constants.GAME_WIDTH / 2, Constants.GAME_HEIGHT - 60, "dash")
      .setDepth(10);

    const rectMask = this.add.graphics();
    const cooldown = this.add.graphics().setDepth(dashImage.depth + 1);

    // gold border
    this.add
      .rectangle(dashImage.x, dashImage.y, dashImage.width, dashImage.height)
      .setStrokeStyle(3, 0xd9bd78)
      .setDepth(dashImage.depth + 2);

    // cooldown timer (number)
    const cooldownTimer = this.add
      .text(dashImage.x, dashImage.y, "1.0")
      .setOrigin(0.5)
      .setDepth(dashImage.depth + 3)
      .setActive(false)
      .setVisible(false);

    rectMask.fillStyle(0, 0);
    rectMask
      .fillRect(0, 0, dashImage.width, dashImage.height)
      .setPosition(dashImage.x - dashImage.width / 2, dashImage.y - dashImage.height / 2);

    cooldown.setMask(rectMask.createGeometryMask());

    // tween the blue cooldown circle effect

    // tween the cooldown text timer
    const cooldownTimerTween = this.tweens.addCounter({
      from: Constants.PLAYER_DASH_COOLDOWN_MS,
      to: 0,
      paused: true,
      duration: Constants.PLAYER_DASH_COOLDOWN_MS,
      ease: "Linear",
      onUpdate: (t) => {
        cooldownTimer.setText(
          Phaser.Math.RoundTo(t.getValue() / 1000, -1)
            .toFixed(1)
            .toString()
        );
      },
    });

    this.player.on("enter-dash", () => {
      this.runCooldownTween(cooldown, dashImage);
      cooldownTimerTween.play();
      cooldownTimer.active = true;
      cooldownTimer.visible = true;
    });

    this.player.on("dash-ready", () => {
      cooldownTimer.active = false;
      cooldownTimer.visible = false;
    });
  }

  // duration has a few MS added onto the value, to make the cooldown circle animation slightly longer - this is to make the game feel more flow-like
  // doesn't affect gameplay, only the UI aspect
  // had to set it up like this because tween.restart() was proving to be a nightmare and didn't update (t) value within onUpdate function properly sometimes
  private runCooldownTween = (
    cooldown: Phaser.GameObjects.Graphics,
    dashImage: Phaser.GameObjects.Image
  ) => {
    if (this.cooldownTween?.isPlaying()) {
      this.cooldownTween?.stop();
    }
    const fromValue = 0;
    const toValue = 360;
    this.cooldownTween = this.tweens.addCounter({
      from: fromValue,
      to: toValue,
      duration: Constants.PLAYER_DASH_COOLDOWN_MS + 100,
      ease: "Linear",
      onUpdate: (t) => {
        cooldown.clear();
        cooldown.fillStyle(0x0a28b4, 0.8);
        cooldown.slice(
          dashImage.x,
          dashImage.y,
          Math.hypot(dashImage.width, dashImage.height) / 2,
          Phaser.Math.DegToRad(270),
          Phaser.Math.DegToRad(-90 + t.getValue()),
          true
        );
        // console.log("running ... ", Math.round(t.getValue()));
        cooldown.fillPath();
      },
      onComplete: () => {
        // console.warn("tween complete!");
      },
    });
  };
}
