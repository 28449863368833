import Constants from "../../Constants";
import Metronome from "../../metronome/Metronome";

export interface ISprite {
  metronome: Metronome;
  playGenericAnimation(
    // sprite: Phaser.GameObjects.Sprite,
    animation: string,
    repeat?: number,
    frameRate?: number,
    ignoreIfPlaying?: boolean,
    playReverse?: boolean
  ): void;
}

/**
 * Avoiding Code Duplication
 */
export default abstract class BaseSpriteMixin {
  /**
   * Play an Aseprite animation
   * @param sprite The sprite that will play the animation - passed in through the class prototyping this method
   * @param animation Aseprite animation key - keys can be found in the preloading scene
   * @param repeat Number of times to repeat; default to -1 for indefinite
   * @param frameRate Animation frame rate; default 24
   * @param ignoreIfPlaying If an animation is already playing, then ignore this call - i.e. finish the current animation
   */
  public playGenericAnimationMixin(
    sprite: Phaser.GameObjects.Sprite,
    animation: string,
    repeat = -1,
    frameRate = Constants.DEFAULT_ANIMATION_FRAME_RATE,
    ignoreIfPlaying = false,
    playReverse = false
  ) {
    if (playReverse) {
      sprite.playReverse(
        {
          key: animation,
          repeat: repeat,
          frameRate: frameRate,
        },
        ignoreIfPlaying
      );
    } else {
      sprite.play(
        {
          key: animation,
          repeat: repeat,
          frameRate: frameRate,
        },
        ignoreIfPlaying
      );
    }
  }
}
