// interface structure for all State classes
export interface State<T> {
  stateMachine: StateMachine<T>;
  enter: (scene: Phaser.Scene, object: T, ...args: any[]) => void;
  update: (scene: Phaser.Scene, object: T, time: number) => void;
  exit: (scene: Phaser.Scene, object: T) => void;
}

export type PossibleStates<T> = {
  [key: string]: State<T>;
};

/**
 * `possibleStates` is an object whose keys refer to the state name and whose values are instances of the `State` interface (or classes / subclasses).
 * The class assigns the `playerStateMachine` property on each instance so they can call `this.playerStateMachine.transition` whenever they want to trigger a transition.
 * `stateArgs` is a list of arguments passed to the `enter` and `execute` functions. This allows us to pass commonly-used values (such as a sprite object or current Phaser Scene) to the state methods.
 */
export default class StateMachine<T> {
  private state: string | null;
  private initialState: string | null;
  private possibleStates: PossibleStates<T> | null;
  private scene: Phaser.Scene;
  private object: T;

  constructor(scene: Phaser.Scene, object: T) {
    this.state = null;
    this.initialState = null;
    this.possibleStates = null;
    this.scene = scene;
    this.object = object;
  }

  public get getState(): string | null {
    return this.state;
  }

  public get getPossibleStates(): PossibleStates<T> | null {
    return this.possibleStates;
  }

  public get getPossibleStatesStringify(): string[] {
    return Object.keys(this.possibleStates ?? []);
  }

  /**
   * Add states to the state machine - this must be run after initialising the state machine since the other methods rely on it
   * We need this separately because the each of the states requires a reference to the state machine, so machine must be init first, then add the states
   * @param possibleStates possible states the state machine can have
   */
  addStates(possibleStates: PossibleStates<T>) {
    this.possibleStates = possibleStates;
    for (const state of Object.values(this.possibleStates)) {
      state.stateMachine = this;
    }
  }

  /**
   * After adding the states to the state machine, initialise a state for it to start off with, i.e. IDLE
   * @param initialState initial state for the state machine to have
   */
  initialiseState(initialState: string) {
    if (!this.checkStateExists(initialState)) return;
    this.initialState = initialState;
  }

  /**
   * This method should be called in the Scene's update() loop
   */
  step(time: number) {
    if (!this.possibleStates || !this.initialState) return;
    // console.log("Current State:", this.state);
    // On the first step, the state is null and needs to be initialized
    if (this.state === null) {
      this.state = this.initialState;
      this.possibleStates[this.state].enter(this.scene, this.object);
    }

    this.possibleStates[this.state].update(this.scene, this.object, time); // run the current state's execute method
  }

  /**
   * Transitions to a new state. Will call the current state's exit function once, and then the new state's enter function once (if it exists)
   * @param newState The new state (string) to transition to - used as an index
   */
  transition(newState: string, ...args: any[]) {
    if (!this.possibleStates || !this.checkStateExists(newState) || !this.state) return; // check that the state exists first
    // console.warn("TRANSITION");
    // console.log("Transition State:", newState);
    // console.log(this.checkStateExists(newState));
    this.possibleStates[this.state].exit(this.scene, this.object); // exit out of the current state
    this.state = newState; // assign new state to this.state
    this.possibleStates[this.state].enter(this.scene, this.object, ...args); // enter the new state
  }

  /**
   * Check if the given state (string) exists within the state machine - `this.possibleStates`
   * This stops unwanted errors occurring if a given state can not be found within the state machine
   * @param checkState State (string) to transition to - string is used as index within `this.possibleStates` with the help of `Object.keys`
   * @returns `true` if state exists, otherwise `false`
   */
  checkStateExists(checkState: string): boolean {
    if (!this.possibleStates) return false;
    if (Object.keys(this.possibleStates).includes(checkState)) {
      return true;
    }
    console.warn("State Does Not Exist!");
    return false;
  }
}
