import Phaser from "phaser";
import Constants from "./Constants";
import BootScene from "./scenes/init/00_BootScene";
import DebugScene from "./scenes/DebugScene";
import LoadingScene from "./scenes/init/02_LoadingScene";
import TutorialScene from "./scenes/TutorialScene";
import UIScene from "./scenes/UIScene";
import TitleScene from "./scenes/init/01_TitleScene";

// VERY nifty for quickly enabling / disabling debug - use "?debug=true" in the URL (query param)
const params = new URLSearchParams(window.location.search);
const enableDebug = params.get("debug") === "true";

const scenes = [
  BootScene, // first scene to load
  TitleScene,
  LoadingScene,
  TutorialScene,
  UIScene,
  DebugScene,
];

const config: Phaser.Types.Core.GameConfig = {
  title: "Tuba Quest",
  autoFocus: true,
  pixelArt: true,
  type: Phaser.AUTO,
  parent: "phaser-game",
  width: Constants.GAME_WIDTH,
  height: Constants.GAME_HEIGHT,
  input: {
    gamepad: true,
  },
  scale: {
    mode: Phaser.Scale.FIT,
    autoCenter: Phaser.Scale.CENTER_BOTH,
  },
  physics: {
    default: "arcade",
    arcade: {
      debug: enableDebug, // determines if debugging features should be enabled or not
      debugShowBody: true,
      debugShowStaticBody: true,
      // gravity: { y: 1500 }
    },
  },
  audio: {
    disableWebAudio: true,
  },
  scene: scenes,
};

new Phaser.Game(config);
