import { clone } from "lodash";
import Constants, { AbilityType, MetronomeIntervals } from "../../../../Constants";
import { BaseAbility } from "../../../../ability/BaseAbility";
import { metronomeIntervalExtender } from "../../../../helpers/Helpers";
import BaseGameSprite from "../../../base/BaseGameSprite";
import NoteMissile from "../../../note-missile/NoteMissile";
import Boss from "../../Boss";
/**
 * /////////////////////////////////////////////////////////////////////////////////////////////////////////
 *
 * Boss Ability - Note Missile
 *
 */
export class BossAbilityNoteMissile extends BaseAbility {
  boss: Boss;
  scene: Phaser.Scene;

  private _defaultCost = -1;
  private _ellipse: Phaser.Geom.Ellipse;
  private _minimumAmountOfNotes = 4;
  private _maximumAmountOfNotes = 6;
  private _amountOfNotes = 0; // set later
  private _noteSpeed = 3000;

  constructor(boss: Boss, weight = 2, cost = -1) {
    super(
      AbilityType.INSTANT,
      "noteMissile",
      boss.metronome,
      {
        windup: MetronomeIntervals.INTERVAL_BAR_1, // start
        action: MetronomeIntervals.INTERVAL_BAR_OFFSET_1, // end
      },
      weight,
      cost
    );
    this.boss = boss;
    this.scene = boss.scene;

    this._defaultCost = cost;
    this._ellipse = new Phaser.Geom.Ellipse(
      0, // to be set later
      0, // to be set later
      700,
      500
    );
  }

  /**
   * If we need to run additional logic before running ability - i.e. dynamic cost updates
   */
  public initAbility() {
    this.cost = this._defaultCost; // default
    this._amountOfNotes =
      Phaser.Math.Between(this._minimumAmountOfNotes, this._maximumAmountOfNotes) * 2; // ensure multiple of 2
    // update cost temporarily
    this.cost = this.cost * this._amountOfNotes; // base the cost on the amount of notes chosen, i.e. 1 for every note spawned
  }

  public abilityWindUp() {
    this.emit(`${this.name}-windup-complete`); // in this case, we don't care about animation complete, just exit straight out of windup

    // this.boss.playGenericAnimation("Priest_Summon_W", 0, 6);
    // Custom animation TODO figure out better way
    this.boss.play({
      key: "Priest_Summon_W",
      // repeat: 0,
      frameRate: Constants.DEFAULT_ANIMATION_FRAME_RATE,
      yoyo: true,
    });

    // odd case where we deal with action-complete event in windup function
    this.boss.once("animationcomplete", () => {
      this.emit(`${this.name}-action-complete`);
    });
  }

  public abilityTension() {
    // this.boss.playGenericAnimation("Priest_Summon_T", -1, 24);
  }

  public abilityAction() {
    // summon notes on their on accord
    // this can be in either windup or action, doesn't really matter as long as the animation matches
    let notes: NoteMissile[] = [];
    const localAmountOfNotes = clone(this._amountOfNotes); // create a local copy of the amount of notes - as it might change when recalling the "initAbility()"
    const uuid = Phaser.Utils.String.UUID(); // ensure the uniqueness of events emitted - so we don't overlap with other Note Missile ability calls

    this._ellipse.setPosition(this.boss.body.center.x, this.boss.body.center.y);

    // under boss collision sprites, find player
    const playerRef = this.boss.collisionSprites.find(
      (o) => o.name === Constants.PLAYER_NAME
    ) as BaseGameSprite;

    // simple loop to create notes
    for (let i = 0; i < localAmountOfNotes; i++) {
      // console.log("create note", i);
      const newNote = new NoteMissile(
        this.scene,
        0,
        0,
        this.boss.metronome,
        `note_${i}`,
        this._noteSpeed,
        playerRef,
        [playerRef]
      );
      newNote.setVisible(false);
      newNote.setScale(2);
      notes.push(newNote);
    }

    // place notes on circumference of ellipse
    Phaser.Actions.PlaceOnEllipse(notes, this._ellipse);

    // go through shortest metronome interval and reveal each note
    let counter = 0;
    const noteLogic = () => {
      if (counter === localAmountOfNotes) {
        console.warn("max notes reached");
        // this.emit(`${this.name}-action-complete`);
        this.emit(`notes_spawned_${uuid}`);
        this.metronome.off(MetronomeIntervals.INTERVAL_BAR_025, noteLogic, this);
        return;
      }
      // console.log(notes);
      // console.log(counter, localAmountOfNotes);
      // console.log(notes[counter]);
      notes[counter].spawn();
      counter++;
    };
    this.metronome.on(MetronomeIntervals.INTERVAL_BAR_025, noteLogic, this);

    // once all notes have "spawned", then transform them to missiles
    // kinda ugly event nesting here ...
    this.once(`notes_spawned_${uuid}`, () => {
      this.metronome.once(MetronomeIntervals.INTERVAL_BAR_1, () => {
        console.warn("TRANSFORM TO MISSILE");
        notes.forEach((o) => {
          o.transformToMissile();
        });
        metronomeIntervalExtender(
          "notes_extender",
          this.metronome,
          MetronomeIntervals.INTERVAL_BAR_1,
          () => {
            notes.forEach((o) => {
              o.chargeUp();
              this.metronome.once(MetronomeIntervals.INTERVAL_BAR_1, () => {
                o.fireLogic();
              });
            });
          },
          this,
          false,
          true
        );
        // // then charge up and fire
        // this.metronome.once(MetronomeIntervals.INTERVAL_BAR_1, () => {
        //   console.warn("CHARGE UP AND FIRE");
        //   notes.forEach((o) => {
        //     o.chargeUpAndFire();
        //   });
        // });
      });
    });

    this.once("notes_extender_OFF", () => {
      // clear everything for garbage collection
      notes = [];
      // TODO etc.
    });
  }

  //   public abilityInterrupted() {
  //     //
  // }
}
