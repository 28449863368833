import Constants from "../../Constants";

// if we need to do some initial stuff before anything happens in the game, do it here

export default class BootScene extends Phaser.Scene {
  constructor() {
    super(Constants.BOOT_SCENE_KEY);
  }

  init() {
    console.log("INIT BOOT SCENE");
    console.log("PERCUSSIVE JUGGERNAUT LOAD");
  }

  preload() {
    // do a little cheeky pre-pre load for the percussive juggernaut, then start the loading scene
    this.load.aseprite(
      "percussive_juggernaut",
      "assets/temp/aseprite/percussive_juggernaut.png",
      "assets/temp/aseprite/percussive_juggernaut.json"
    );

    // fonts
    this.load.bitmapFont("default", "assets/fonts/algard.png", "assets/fonts/algard.fnt");
    this.load.bitmapFont("debug", "assets/fonts/pixel.png", "assets/fonts/pixel.fnt");
  }

  create() {
    this.anims.createFromAseprite("percussive_juggernaut");
    // this.scene.start(Constants.TITLE_SCENE_KEY);
    this.scene.start(Constants.LOADING_SCENE_KEY); // or skip straight to loading scene - for development purposes
  }
}
