import { AbilityType, MetronomeIntervals } from "../../../../Constants";
import { BaseAbility } from "../../../../ability/BaseAbility";
import Boss from "../../Boss";

/**
 * /////////////////////////////////////////////////////////////////////////////////////////////////////////
 *
 * Boss Ability - Standard Regenerate Respiration (stamina)
 *
 */
export class BossAbilityRegenerate extends BaseAbility {
  boss: Boss;
  scene: Phaser.Scene;
  upArrow: Phaser.GameObjects.Image;

  constructor(boss: Boss, weight?: number, cost?: number) {
    super(
      AbilityType.CHANNEL,
      "regen-respiration",
      boss.metronome,
      {
        windup: MetronomeIntervals.INTERVAL_BAR_1, // start
        action: MetronomeIntervals.INTERVAL_BAR_2, // duration
      },
      weight ?? 0,
      cost ?? 0
      // maybe have just the base ability with not mana cost, then make a BaseAbility and extend the mana functions - although annoying
      // I'd like a way where we can just have one BaseAbility, and its optional to do additional cost logic if needed ...
    );
    this.boss = boss;
    this.scene = boss.scene;
    this.upArrow = this.scene.add
      .image(0, 0, "up_arrow")
      .setScale(0.25)
      .setDepth(this.boss.depth + 1)
      .setActive(false)
      .setVisible(false);
  }

  public abilityWindUp() {
    // when windup starts, recalculate amount of cost to regenerate, based on max respiration
    this.cost = this.boss.resourceValueMax - this.boss.resourceValueCurr;
    this.upArrow.setPosition(this.boss.x, this.boss.y - 200);
    this.upArrow.setActive(true).setVisible(true);
    // this.scene.time.delayedCall(100, () => this.emit(`${this.name}-windup-complete`)); // replace with some sort of windup animation, otherwise keep this call, due to the 'instantaneous' problem
    this.emit(`${this.name}-windup-complete`);
  }

  public abilityTension() {
    //
  }

  public abilityAction() {
    this.upArrow.setActive(false).setVisible(false);
    this.cost = 0; // reset cost back to 0, to be recalculated next iteration
    this.emit(`${this.name}-action-complete`);
  }

  public abilityInterrupted() {
    //
  }
}
