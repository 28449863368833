import Constants, { AbilityType, MetronomeIntervals } from "../../../../Constants";
import { BaseAbility } from "../../../../ability/BaseAbility";
import Boss from "../../Boss";
/**
 * /////////////////////////////////////////////////////////////////////////////////////////////////////////
 *
 * Boss Ability - Teleport
 *
 */
export class BossAbilityTeleport extends BaseAbility {
  boss: Boss;
  scene: Phaser.Scene;

  constructor(boss: Boss, weight = 1, cost = -5) {
    super(
      AbilityType.INSTANT,
      "teleport",
      boss.metronome,
      {
        windup: MetronomeIntervals.INTERVAL_BAR_OFFSET_1, // start
        action: MetronomeIntervals.INTERVAL_BAR_1, // end
      },
      weight,
      cost
    );
    this.boss = boss;
    this.scene = boss.scene;
  }

  public abilityWindUp() {
    this.boss.playGenericAnimation("Priest_Teleport", 0);
    this.boss.once("animationcomplete", () => {
      this.emit(`${this.name}-windup-complete`);
    });
  }

  public abilityTension() {
    // this.boss.playGenericAnimation("Priest_Summon_T", -1);
  }

  public abilityAction() {
    const x = Phaser.Math.Between(
      this.boss.displayWidth,
      Constants.GAME_WIDTH - this.boss.displayWidth
    );
    const y = Phaser.Math.Between(
      this.boss.displayHeight,
      Constants.GAME_HEIGHT - this.boss.displayHeight
    );
    // TODO also check for where player is, so we don't spawn on top of player
    this.boss.setPosition(x, y);

    this.boss.playGenericAnimation(
      "Priest_Teleport",
      0,
      Constants.DEFAULT_ANIMATION_FRAME_RATE,
      undefined,
      true
    ); // replace with action animation

    this.boss.once("animationcomplete", () => {
      this.emit(`${this.name}-action-complete`);
    });
  }

  public abilityInterrupted() {
    //
  }
}
