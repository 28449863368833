import { Physics } from "phaser";
import Constants, { MetronomeIntervals } from "../Constants";
import Metronome from "../metronome/Metronome";
import { isUndefined } from "lodash";
import BaseSprite from "../sprites/base/BaseSprite";
import BasePhysicsSprite from "../sprites/base/BasePhysicsSprite";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Type Guards

// body property
export function isPhysicsBody(
  body: Physics.Arcade.Body | Physics.Arcade.StaticBody | MatterJS.BodyType
): body is Physics.Arcade.Body {
  return (body as Physics.Arcade.Body) !== undefined;
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Metronome

export function convertMetronomeIntervalToPeriod(interval: MetronomeIntervals) {
  switch (interval) {
    case MetronomeIntervals.INTERVAL_BAR_025: // shortest interval at the moment
      return 0.25;
    case MetronomeIntervals.INTERVAL_BAR_05:
      return 0.5;
    case MetronomeIntervals.INTERVAL_BAR_OFFSET_1:
      return 1;
    case MetronomeIntervals.INTERVAL_BAR_1:
      return 1;
    case MetronomeIntervals.INTERVAL_BAR_2:
      return 2;
    case MetronomeIntervals.INTERVAL_BAR_4:
      return 4;
    default:
      return 0;
  }
}

/**
 * Nice way to simply chain animations
 * Pass a Sprite and animation array, and it will play each one in sequence
 * @param sprite
 * @param animsArr
 */
export const chainAnimations = (sprite: BaseSprite | BasePhysicsSprite, animsArr: string[]) => {
  // const animations = ["Missile_Note", "Missile_Note_Collapse", "Missile_Idle"];
  // chainAnimations(this, animations);

  const firstAnim = animsArr.shift() ?? "NO_ANIMATION"; // take first one
  console.log(firstAnim);

  const onAnimationComplete = (anim: Phaser.Animations.AnimationFrame) => {
    console.log("animationcomplete", anim);

    const nextAnim = animsArr.shift();
    console.log(nextAnim);
    nextAnim
      ? sprite.playGenericAnimation(nextAnim, 0)
      : sprite.off("animationcomplete", onAnimationComplete);
  };

  sprite.playGenericAnimation(firstAnim, 0);
  sprite.on("animationcomplete", onAnimationComplete);
};

//////////////////////////

/**
 * A way to extend the duration of some specific function using the shortest metronome interval
 * This way, everything is run by ONE metronome interval (shortest one) and no weird overlapping / race condition bugs should happen, due to multiple intervals etc.
 *
 * E.g. if the shortest metronome interval is x0.25 (4 times shorter) than the main quarter-bar tempo (interval bar 1), and you wanted to mimic an interval bar 1,
 * then passing through a `MetronomeIntervals.INTERVAL_BAR_1` will essentially be counting 4 times, and then the function logic will be executed
 *
 * @param name identifier for emitting events
 * @param metronome the metronome object
 * @param metronomeInterval the metronome interval DURATION we want to mimic
 * @param fn the main function to execute
 * @param context any context
 * @param once if true, remove listener after counter fulfilled, otherwise run forever - default true
 * @param printDebug if you want to see console logging
 * @param counterStart where to start the counter for timing purposes; not recommended to change but here anyway - default 1
 */
export function metronomeIntervalExtender(
  name: string,
  metronome: Metronome,
  metronomeInterval: MetronomeIntervals,
  fn: () => void,
  context?: unknown,
  once = true,
  printDebug = false,
  counterStart = 1
) {
  if (printDebug) console.debug(`${name} ON`);

  // initialise counters
  let myCounter = counterStart;

  // get event listener counter max (interval we are trying to mimic)
  const counterMax =
    convertMetronomeIntervalToPeriod(metronomeInterval) /
    convertMetronomeIntervalToPeriod(Constants.SHORTEST_METRONOME_INTERVAL);

  const metronomeIntervalExtenderCounter = () => {
    // metronome.emit(`${name}_counter`, myCounter);

    if (myCounter < counterMax) {
      if (printDebug) console.debug(`myCounter++: ${myCounter} => ${myCounter + 1}`);
      myCounter++;
      return;
    }

    if (once) metronome.emit(`${name}_OFF`);

    // debug stuff
    if (printDebug) {
      console.debug(`myCounter finished at ${myCounter} - calling main function`);
      console.debug(
        JSON.stringify(
          {
            name: name,
            myCounter: myCounter,
          },
          null,
          4
        )
      );
    }

    // reset counter back to start
    myCounter = counterStart;

    // once myCounter condition has been reached, finally call the main function
    fn.call(context);

    metronome.emit(`${name}_OFF`);
  };

  // call metronomeIntervalExtenderCounter for counter logic
  metronome.on(Constants.SHORTEST_METRONOME_INTERVAL, metronomeIntervalExtenderCounter, context); // always listener for lowest metronome interval

  // emit this event to end this interval - called once counter is fulfilled
  // if you want to turn off this interval timer early for whatever reason, you can emit this event manually
  metronome.once(`${name}_OFF`, () => {
    if (printDebug) console.debug(`${name} OFF`);
    metronome.off(Constants.SHORTEST_METRONOME_INTERVAL, metronomeIntervalExtenderCounter, context);
  });
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Other

// pure trolling - for testing purposes - if too laggy, reduce LAG_O_METER_NUMBER variable
export function LAG_O_METER(scene: Phaser.Scene) {
  const LAG_O_METER_NUMBER = 10;
  for (let index = 0; index < LAG_O_METER_NUMBER; index++) {
    scene.add.circle(Constants.GAME_WIDTH / 2, Constants.GAME_HEIGHT / 2, 10, 0xff0000, 1); // will show up in performance tab call tree as 'ArcWebGLRenderer'
  }
}
