import Constants from "../../Constants";
import BasePhysicsSprite from "./BasePhysicsSprite";
import Metronome from "../../metronome/Metronome";
import { isNil } from "lodash";

export default class BaseProjectile extends BasePhysicsSprite {
  private _projectileSpeed: number;
  private _target?: BasePhysicsSprite;

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    texture: string,
    metronome: Metronome,
    name: string,
    // optional
    projectileSpeed = 0, // projectile
    target?: BasePhysicsSprite, // projectile - if there is a target
    collisionSprites: BasePhysicsSprite[] = []
  ) {
    super(scene, x, y, texture, metronome, name, false, collisionSprites /*frame*/);
    this._projectileSpeed = projectileSpeed;
    this._target = target;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Getters + Setters
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  public get projectileSpeed(): number {
    return this._projectileSpeed;
  }

  public get target(): BasePhysicsSprite | undefined {
    return this._target;
  }
  /**
   * Override - Usually all projectiles should be OVERLAP not COLLIDER
   */
  public collisionLogic(): void {
    console.log("OVERLAP", this.name, this.collisionSprites);
    this.scene.physics.add.overlap(this, this.collisionSprites, () => {
      this.hasCollided = true;
      this.kill();
    });
  }

  protected preUpdate(time: number, delta: number) {
    super.preUpdate(time, delta);

    // if projectile goes out of bounds of the game
    // TODO do we want them to just be inactive, or fully destroy them? in the case of groups, we do NOT want to destroy them
    if (
      this.y <= 0 ||
      this.y >= Constants.GAME_HEIGHT ||
      this.x <= 0 ||
      this.x >= Constants.GAME_WIDTH
    ) {
      this.kill();
      // this.setActive(false);
      // this.setVisible(false);
      // this.body.stop();
      // this.emit("projectileOutOfBounds", this); // emit event in the case additional logic is required
    }
  }

  /**
   * Fire projectile, relative to its own position, to the target sprite
   */
  public fireToTarget() {
    if (isNil(this.target)) {
      console.warn("Fire To Target Failed - No Target");
      return;
    }
    // TODO
    // this.setVelocity(this.target.x, this.target.y);
  }

  /**
   * Fire projectile, relative to its own position, to a given position
   * @param position Vector2
   */
  public fireToGivenPosition(position: Phaser.Math.Vector2 | undefined) {
    if (isNil(position)) return;
    const normaliseDirectionVector = position
      .clone()
      .subtract(new Phaser.Math.Vector2(this.x, this.y))
      .normalize()
      .scale(this.projectileSpeed);
    this.setVelocity(normaliseDirectionVector.x, normaliseDirectionVector.y);
  }

  // TODO REDO
  public fireVelocity(positionX: number, positionY: number, velocityX: number, velocityY: number) {
    this.body.reset(positionX, positionY);
    this.setActive(true);
    this.setVisible(true);
    this.setVelocity(velocityX, velocityY);
  }

  // TODO REDO
  public fireToPoint2(positionX: number, positionY: number, velocity: number) {
    // this.body.reset(positionX1, positionY1);
    // this.setActive(true);
    // this.setVisible(true);
    const normaliseDirectionVector = new Phaser.Math.Vector2(positionX, positionY)
      .clone()
      .subtract(new Phaser.Math.Vector2(this.x, this.y))
      .normalize()
      .scale(velocity);
    this.setVelocity(normaliseDirectionVector.x, normaliseDirectionVector.y);
  }

  /**
   * List of validation statements - will return true if match, else false
   * Mainly a test to see if this style / format works
   */
  public validate(arr: string[]): boolean {
    for (const v of arr) {
      switch (v) {
        // case "ACTIVE":
        //   if (!this.active) {
        //     console.warn("FAILED VALIDATION - Sprite not active");
        //     return true;
        //   }
        //   break;
        // case "ANIMS":
        //   if (this.anims.isPlaying) {
        //     console.warn("FAILED VALIDATION - Sprite already playing animation");
        //     return true;
        //   }
        //   break;
        case "HAS_COLLIDED":
          // figured out here that we don't need all the above, just the hasCollided variable to determine the validation etc.
          if (this.hasCollided) {
            console.warn("FAILED VALIDATION - Projectile has collided, destroying in progress ...");
            return true;
          }
          break;
      }
    }
    return false;
  }
}
