{
  "frames": {
    "0": {
      "frame": { "x": 1, "y": 676, "w": 41, "h": 320 },
      "rotated": false,
      "trimmed": true,
      "spriteSourceSize": { "x": 65, "y": 38, "w": 39, "h": 318 },
      "sourceSize": { "w": 170, "h": 375 },
      "duration": 100
    },
    "1": {
      "frame": { "x": 85, "y": 676, "w": 41, "h": 319 },
      "rotated": false,
      "trimmed": true,
      "spriteSourceSize": { "x": 65, "y": 39, "w": 39, "h": 317 },
      "sourceSize": { "w": 170, "h": 375 },
      "duration": 100
    },
    "2": {
      "frame": { "x": 378, "y": 340, "w": 41, "h": 332 },
      "rotated": false,
      "trimmed": true,
      "spriteSourceSize": { "x": 65, "y": 26, "w": 39, "h": 330 },
      "sourceSize": { "w": 170, "h": 375 },
      "duration": 100
    },
    "3": {
      "frame": { "x": 336, "y": 340, "w": 41, "h": 334 },
      "rotated": false,
      "trimmed": true,
      "spriteSourceSize": { "x": 65, "y": 24, "w": 39, "h": 332 },
      "sourceSize": { "w": 170, "h": 375 },
      "duration": 100
    },
    "4": {
      "frame": { "x": 238, "y": 340, "w": 55, "h": 336 },
      "rotated": false,
      "trimmed": true,
      "spriteSourceSize": { "x": 58, "y": 22, "w": 53, "h": 334 },
      "sourceSize": { "w": 170, "h": 375 },
      "duration": 100
    },
    "5": {
      "frame": { "x": 132, "y": 340, "w": 105, "h": 337 },
      "rotated": false,
      "trimmed": true,
      "spriteSourceSize": { "x": 33, "y": 21, "w": 103, "h": 335 },
      "sourceSize": { "w": 170, "h": 375 },
      "duration": 100
    },
    "6": {
      "frame": { "x": 1, "y": 338, "w": 130, "h": 337 },
      "rotated": false,
      "trimmed": true,
      "spriteSourceSize": { "x": 19, "y": 21, "w": 128, "h": 335 },
      "sourceSize": { "w": 170, "h": 375 },
      "duration": 100
    },
    "7": {
      "frame": { "x": 283, "y": 1, "w": 136, "h": 338 },
      "rotated": false,
      "trimmed": true,
      "spriteSourceSize": { "x": 18, "y": 20, "w": 134, "h": 336 },
      "sourceSize": { "w": 170, "h": 375 },
      "duration": 100
    },
    "8": {
      "frame": { "x": 144, "y": 1, "w": 138, "h": 338 },
      "rotated": false,
      "trimmed": true,
      "spriteSourceSize": { "x": 17, "y": 20, "w": 136, "h": 336 },
      "sourceSize": { "w": 170, "h": 375 },
      "duration": 100
    },
    "9": {
      "frame": { "x": 1, "y": 1, "w": 142, "h": 336 },
      "rotated": false,
      "trimmed": true,
      "spriteSourceSize": { "x": 16, "y": 22, "w": 140, "h": 334 },
      "sourceSize": { "w": 170, "h": 375 },
      "duration": 100
    },
    "10": {
      "frame": { "x": 294, "y": 340, "w": 41, "h": 335 },
      "rotated": false,
      "trimmed": true,
      "spriteSourceSize": { "x": 65, "y": 23, "w": 39, "h": 333 },
      "sourceSize": { "w": 170, "h": 375 },
      "duration": 100
    },
    "11": {
      "frame": { "x": 378, "y": 673, "w": 41, "h": 331 },
      "rotated": false,
      "trimmed": true,
      "spriteSourceSize": { "x": 65, "y": 27, "w": 39, "h": 329 },
      "sourceSize": { "w": 170, "h": 375 },
      "duration": 100
    },
    "12": {
      "frame": { "x": 336, "y": 675, "w": 41, "h": 322 },
      "rotated": false,
      "trimmed": true,
      "spriteSourceSize": { "x": 65, "y": 36, "w": 39, "h": 320 },
      "sourceSize": { "w": 170, "h": 375 },
      "duration": 100
    },
    "13": {
      "frame": { "x": 43, "y": 676, "w": 41, "h": 320 },
      "rotated": false,
      "trimmed": true,
      "spriteSourceSize": { "x": 65, "y": 39, "w": 39, "h": 318 },
      "sourceSize": { "w": 170, "h": 375 },
      "duration": 100
    }
  },
  "meta": {
    "app": "https://www.aseprite.org/",
    "version": "1.2.40-x64",
    "image": "Organ_Shooter.png",
    "format": "RGBA8888",
    "size": { "w": 419, "h": 1004 },
    "scale": "1",
    "frameTags": [
      { "name": "Organ_Shooter", "from": 0, "to": 13, "direction": "forward" },
      { "name": "Projectile", "from": 14, "to": 14, "direction": "forward" }
    ],
    "layers": [
      { "name": "bottom", "opacity": 255, "blendMode": "normal" },
      { "name": "organ pipe", "opacity": 255, "blendMode": "normal" },
      { "name": "smoke", "opacity": 255, "blendMode": "normal" }
    ],
    "slices": []
  }
}
