import Constants, { AbilityType, MetronomeIntervals } from "../../../../Constants";
import { BaseAbility } from "../../../../ability/BaseAbility";
import { metronomeIntervalExtender } from "../../../../helpers/Helpers";
import TubaDwarf from "../../../tuba-dwarf/TubaDwarf";
import Boss from "../../Boss";
/**
 * /////////////////////////////////////////////////////////////////////////////////////////////////////////
 *
 * Boss Ability - Summon Minions
 *
 */
export class BossAbilitySummonMinions extends BaseAbility {
  boss: Boss;
  scene: Phaser.Scene;
  summonMinionInterval = Constants.SHORTEST_METRONOME_INTERVAL;

  constructor(boss: Boss, weight = 1, cost = -999) {
    super(
      AbilityType.CHANNEL,
      "summon-minions",
      boss.metronome,
      {
        windup: MetronomeIntervals.INTERVAL_BAR_1,
        action: MetronomeIntervals.INTERVAL_BAR_2,
      },
      weight,
      cost
    );
    this.boss = boss;
    this.scene = boss.scene;
  }

  public abilityWindUp() {
    // this.boss.setScale(1.5);
    // this.boss.setPosition(Constants.GAME_WIDTH / 2, 450);
    this.boss.playGenericAnimation("Priest_Summon_W", 0);
    this.boss.once("animationcomplete", () => {
      this.emit(`${this.name}-windup-complete`);
    });

    // // in this example, summon minions ACTION runs during WINDUP
    // metronomeIntervalExtender(
    //   "SUMMON_MINIONS",
    //   this.metronome,
    //   this.summonMinionInterval,
    //   this.bossSummonMinions,
    //   this
    // );
    // metronomeIntervalExtender(
    //   "SUMMON_MINIONS",
    //   this.metronome,
    //   this.summonMinionInterval,
    //   this.bossSummonMinions,
    //   this
    // );
  }

  public abilityTension() {
    this.boss.playGenericAnimation("Priest_Summon_T", -1, 6);
  }

  public abilityAction() {
    this.boss.playGenericAnimation(
      "Priest_Summon_W",
      0,
      Constants.DEFAULT_ANIMATION_FRAME_RATE,
      undefined,
      true
    ); // TODO replace with action animation
    // this.boss.setScale(1);
    // this.boss.setFrame(0); // TODO just temporary fix for animation
    this.metronome.emit("SUMMON_MINIONS_OFF");
    this.boss.once("animationcomplete", () => {
      this.emit(`${this.name}-action-complete`);
    });
  }

  public abilityInterrupted() {
    //
  }

  /**
   * Summon Minions Logic
   * TODO make better
   * @param metronomeInterval
   */
  public bossSummonMinions() {
    // const x = Phaser.Math.Between(
    //   this.boss.displayWidth,
    //   Constants.GAME_WIDTH - this.boss.displayWidth
    // );
    // const y = Phaser.Math.Between(
    //   this.boss.displayHeight,
    //   Constants.GAME_HEIGHT - this.boss.displayHeight
    // );
    // const tubaDwarf = new TubaDwarf(this.scene, x, y, this.boss.metronome, "");
    // tubaDwarf.moveInRandomDirection();
    //
    // // lifespan - name needs to be unique to turn off properly, hence uuid generation
    // metronomeIntervalExtender(
    //   "SUMMON_MINIONS_" + Phaser.Utils.String.UUID(),
    //   this.boss.metronome,
    //   this.summonMinionInterval,
    //   () => tubaDwarf.destroy(),
    //   this
    // );
  }
}
