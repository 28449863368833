import Metronome from "../../metronome/Metronome";
import { CustomTweenManager } from "../../tween/CustomTweenManager";
import BaseSpriteMixin, { ISprite } from "./BaseSpriteMixins";

export default class BaseSprite extends Phaser.GameObjects.Sprite implements ISprite {
  private _metronome: Metronome;
  private _spriteTweenManager: CustomTweenManager;

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    texture: string,
    metronome: Metronome,
    name: string
  ) {
    super(scene, x, y, texture);
    this._metronome = metronome;
    this.name = name;

    this._spriteTweenManager = new CustomTweenManager(scene, this);

    scene.add.existing(this);
    scene.anims.createFromAseprite(texture); // every actor will have as aseprite sheet to animate from, so create one for each instance
    // console.log("BaseSprite");
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  public get metronome(): Metronome {
    return this._metronome;
  }

  /**
   * Call the playGenericAnimationMixin method
   */
  public playGenericAnimation(
    animation: string,
    repeat?: number,
    frameRate?: number,
    ignoreIfPlaying?: boolean,
    playReverse?: boolean
  ) {
    BaseSpriteMixin.prototype.playGenericAnimationMixin(
      this,
      animation,
      repeat,
      frameRate,
      ignoreIfPlaying,
      playReverse
    );
  }
}
