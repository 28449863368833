import Metronome from "../../metronome/Metronome";
import BasePhysicsSprite from "./BasePhysicsSprite";

/**
 * Think of a Base Game Sprite as essentially a fully-fledged character in the game world
 * It will have everything a Physics Sprite will have (movement, collision etc.) but with additional custom properties
 * Such as a resource bar (mana, respiration), health, etc.
 */
export default class BaseGameSprite extends BasePhysicsSprite {
  private _health: number;

  // in the case of Boss, its Respiration - with Player, it would be Valor
  private _resourceValueMin: number; // the lowest value the bar can reach - i.e. stops sprite from using an ability that might consume whole mana bar
  private _resourceValueMax: number;
  private _resourceValueCurr: number;

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    texture: string,
    metronome: Metronome,
    name: string,
    health: number, // game sprite
    resourceValueMin: number, // game sprite
    resourceValueMax: number, // game sprite
    // optional
    resourceValueCurr?: number, // game sprite
    collisionSprites: BasePhysicsSprite[] = []
  ) {
    super(scene, x, y, texture, metronome, name, true, collisionSprites);
    this._health = health;

    this._resourceValueMin = resourceValueMin;
    this._resourceValueMax = resourceValueMax;
    this._resourceValueCurr = resourceValueCurr ?? resourceValueMax; // default to max
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  public get health(): number {
    return this._health;
  }

  public get resourceValueMin(): number {
    return this._resourceValueMin;
  }

  public get resourceValueMax(): number {
    return this._resourceValueMax;
  }

  public get resourceValueCurr(): number {
    return this._resourceValueCurr;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Setters
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  public set resourceValueCurr(v: number) {
    this._resourceValueCurr = v;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Other
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Adjust the resource value of this game sprite
   * E.g. deducting the mana cost of that ability
   * @param v
   */
  public adjustResource(v: number) {
    // // this should never happen, but just in case
    // if (this.resourceValueCurr + v < 0) {
    //   throw new Error("Negative Current Resource Value When Subtracting!");
    // }

    // console.error(v);
    // console.error(this);
    this.resourceValueCurr += v;
    // this.emit("respiration-adjusted-instant");
  }

  /**
   *
   * @param value
   */
  public getDamage(value?: number) {
    // TO DO
  }
}
