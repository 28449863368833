import { DirectionType } from "../../Constants";
import Player, { PlayerCombatStates } from "./Player";
import StateMachine, { State } from "../../states/StateMachine";

/**
 * /////////////////////////////////////////////////////////////////////////////////////////////////////////
 *
 * Player Idle State
 *
 * Idle can transition to:
 *  - Attack
 *  - ...
 *
 * (e.g. you are not able to dash while in Idle state - have to be moving first)
 */
export class IdleState implements State<Player> {
  stateMachine: StateMachine<Player>;

  constructor(stateMachine: StateMachine<Player>) {
    this.stateMachine = stateMachine;
  }

  enter(scene: Phaser.Scene, player: Player) {
    // console.log(" > entering idle...");
    player.emit("enter-idle");

    // use 'once' since the event listener should be destroyed after activation
    player.once("playerPointerDown", (p: Phaser.Input.Pointer) => {
      this.stateMachine.transition(PlayerCombatStates.ATTACK, p);
    });
  }

  update() {
    //
  }

  exit(scene: Phaser.Scene, player: Player) {
    // console.log(" < exiting idle...");
    player.emit("exit-idle");
  }
}

/**
 * /////////////////////////////////////////////////////////////////////////////////////////////////////////
 *
 * Player Attack State
 *
 * Attack can transition to:
 *  - Idle
 *  - ...
 *
 */
export class AttackState implements State<Player> {
  stateMachine: StateMachine<Player>;

  constructor(stateMachine: StateMachine<Player>) {
    this.stateMachine = stateMachine;
  }

  enter(scene: Phaser.Scene, player: Player, ...args: any[]) {
    // console.log(" > entering attack...");
    player.emit("enter-attack");

    const p = args[0] as Phaser.Input.Pointer; // can do this since we know this will arrive as a pointer object from IDLE, and nothing ELSE can transition to ATTACK state (yet)

    // after a delay, stop attacking
    scene.time.delayedCall(player.getAttackDuration, () => {
      // console.log("attack finished");
      this.stateMachine.transition(PlayerCombatStates.IDLE); // always go to idle after attacking
    });

    player.attack(p);
  }

  update(scene: Phaser.Scene, player: Player) {
    //
  }

  exit(scene: Phaser.Scene, player: Player) {
    // console.log(" < exiting attack...");
    player.emit("exit-attack");
  }
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////
// Transition Functions
/////////////////////////////////////////////////////////////////////////////////////////////////////////
