import Constants from "../../Constants";
import Heart, { HeartbeatDirection } from "./Heart";

/**
 * A singular heartbeat pulse that will move across the screen to the heart
 *
 * Plan is to make the left-side heartbeats collision enabled, and right-side heartbeats just normal sprites - both will emit at same time as pairs in a group wrapped entirely in a container
 * Was originally going to make the RIGHT side heartbeats just images / sprites that move, but apparently you can't move them without physics being enabled anyway (could prob do it with `moveTo` but thats long)
 */
export default class Heartbeat extends Phaser.Physics.Arcade.Sprite {
  // private scene: Phaser.Scene;
  private heart: Heart;
  private velocityX: number;
  private direction: string;
  private hasCollidedGood: boolean;
  private hasCollidedGreat: boolean;
  private graceDistance: number; // after the heartbeat has reached its destination (heart), let the BODY continue on for the extra amount of distance to act as a grace period
  private heartbeatFadeTween: Phaser.Tweens.Tween;

  private startX: number;
  private debugText: Phaser.GameObjects.Text | null;

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    key: string,
    heart: Heart,
    velocityX: number,
    direction: HeartbeatDirection,
    name: string
  ) {
    super(scene, x, y, key);

    this.scene.add.existing(this);
    this.scene.physics.add.existing(this);

    this.heart = heart;
    this.velocityX = velocityX;
    this.direction = direction;
    this.hasCollidedGood = false;
    this.hasCollidedGreat = false;
    this.graceDistance = this.heart.getHeartOverlapZoneGreat.width / 2; // make the grace distance essentially spam entire distance of the GREAT zone

    this.body.setSize(1, this.height); // make it 1D
    this.setAlpha(0);

    this.startX =
      this.direction == HeartbeatDirection.LEFT
        ? this.heart.x - this.heart.displayWidth / 2 - Constants.HEARTBEAT_SPAWN_OFFSET
        : this.heart.x + this.heart.displayWidth / 2 + Constants.HEARTBEAT_SPAWN_OFFSET;
    // this.startX = (this.direction == HeartbeatDirection.LEFT ? 350 : Constants.GAME_WIDTH - 350);		// for debugging
    this.name = name;
    this.flipX = this.direction === HeartbeatDirection.LEFT ? false : true;

    // only CREATE the debug text if drawDebug is initialised in the game config index file - otherwise we wasting resource (albeit tiny amount)
    this.debugText = this.scene.physics.world.drawDebug
      ? this.scene.add.text(this.x, this.y, name).setOrigin(0.5, 1)
      : null;

    // create heartbeat fade tween initially, then to be played once fired
    // tween alpha to 1 over the duration (heartbeat alpha is set to 0 initially)
    this.heartbeatFadeTween = this.scene.tweens.add({
      targets: this,
      alpha: 1,
      duration: Constants.HEARTBEAT_FADE_IN_TWEEN_DURATION_MS,
      paused: true,
    });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Setters
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  public set setHeartbeatVelocityX(newVelocityX: number) {
    this.velocityX = newVelocityX;
  }

  public set setHasCollidedGood(bool: boolean) {
    this.hasCollidedGood = bool;
  }

  public set setHasCollidedGreat(bool: boolean) {
    this.hasCollidedGreat = bool;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  public get getHasCollidedGood(): boolean {
    return this.hasCollidedGood;
  }

  public get getHasCollidedGreat(): boolean {
    return this.hasCollidedGreat;
  }

  public get getHeart(): Heart {
    return this.heart;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Functions
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  protected preUpdate(time: number, delta: number) {
    super.preUpdate(time, delta);

    if (this.debugText) {
      this.debugText.x = this.x; // also equivalent to this.body.x + (this.body.width / 2)
      this.debugText.y = this.y - 30;
    }

    // everything left side is collision related
    if (this.direction == HeartbeatDirection.LEFT) {
      // as soon as the heartbeat reaches the center of the heart (or passes it) then make the heartbeat invisible - BUT keep the body moving
      if (this.x >= this.heart.x) {
        this.setVisible(false);
        // once passed, let the body move for a couple more extra pixels, just to account for input lag etc
        if (this.body.x > this.heart.x + this.graceDistance) {
          // console.log("NO HIT DETECTED", this.name);
          this.emit("noInputDetected");
        }
      }
    }

    // negate everything for right side since it is coming in opposite direction - also ignore any collisions for these sprites
    if (this.direction == HeartbeatDirection.RIGHT) {
      if (this.x <= this.heart.x) {
        this.setVisible(false);
        // this.body.width is added to give it an offset of the end of the heartbeat's hit box (change body.setSize to 100 to see this effect exaggerated)
        if (this.body.x + this.body.width < this.heart.x - this.graceDistance) {
          this.resetBody(true);
        }
      }
    }
  }

  public toggleHeartbeatDebug(bool: boolean) {
    if (!this.debugText) return;
    this.debugText.setActive(bool);
    this.debugText.setVisible(bool);
  }

  public fire() {
    // console.log("HEARTBEAT FIRE", this.name, this.direction);
    this.body.reset(this.startX, this.heart.y);
    this.setActive(true);
    this.setVisible(true);
    this.setVelocityX(this.velocityX);
    this.heartbeatFadeTween.play();
  }

  public resetBody(noInput?: boolean) {
    // DEBUGGING - create afterimage of where the heartbeat collided with great zone
    // if (!noInput) {
    //   const hb = this.scene.add
    //     .image(this.x, this.y, "q1")
    //     .setScale(this.scale)
    //     .setDepth(this.depth);
    //   hb.flipX = this.direction === HeartbeatDirection.LEFT ? false : true;
    //   this.scene.tweens.add({
    //     targets: hb,
    //     alpha: 0,
    //     duration: 2000,
    //   });
    // }

    this.setActive(false);
    this.setVisible(false);
    this.hasCollidedGood = false;
    this.hasCollidedGreat = false;
    this.body.reset(this.startX, this.heart.y); // once it has reached the heart, reset back to 0, otherwise the body hit box will keep flying off forever

    if (!this.debugText) return;
    this.debugText.x = this.x;
    this.debugText.y = this.y - 30;
  }
}
