import Constants from "../../Constants";
import { isEmpty } from "lodash";

export default class LoadingScene extends Phaser.Scene {
  private progressBar!: Phaser.GameObjects.Graphics;
  private progressBox!: Phaser.GameObjects.Graphics;
  private loadText!: Phaser.GameObjects.Text;
  private percussiveJugg: Phaser.GameObjects.Sprite | null;
  private loaded: string[];

  constructor() {
    super(Constants.LOADING_SCENE_KEY); // call the scene name 'LoadingScene'
    this.loaded = [];
    this.percussiveJugg = null;
  }

  init() {
    console.log("Loading Assets ...");
    this.percussiveJugg = this.add
      .sprite(Constants.GAME_WIDTH / 2, Constants.GAME_HEIGHT - 200, "")
      .play({
        key: "Body cycle",
        repeat: -1,
      });
  }

  onLoadError(file: any) {
    console.error(file);
  }

  preload() {
    console.time("preload_total_time");

    /* --- progress events ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

    this.load.on("progress", (value: number) => {
      this.progressBar
        .clear()
        .fillStyle(0xffffff, 1)
        .fillRect(Constants.GAME_WIDTH / 2 - 170, Constants.GAME_HEIGHT / 2 - 15, 340 * value, 30);
      this.loadText.setText(Math.round(value * 100) + "%");
    });

    this.load.on("load", (file: Phaser.Loader.File) => {
      this.loaded.push(file.key);
    });

    this.load.on("loaderror", (file: any) => console.error(file));

    this.load.on("filecomplete", (file: string) => {
      console.log("Load Successful:", file);
      this.loaded = this.loaded.filter((e) => e !== file); // filter out (all instances of) the file from the loaded array
    });

    this.load.on("complete", () => {
      console.log("Total Files Loaded Successfully:", this.load.totalComplete);
      console.log("Total Files Failed (Scene Loader):", this.load.totalFailed);
      console.log("Total Files Failed (Custom Checker):", this.loaded.length);

      if (!isEmpty(this.loaded)) {
        this.loaded.forEach((e) => console.error("LOAD ERROR! FILE:", e));
      }
    });

    /* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

    // some set up for the loading screen
    this.cameras.main.setBackgroundColor(0x111111); // background colour
    this.progressBar = this.add.graphics(); // progress bar
    this.progressBox = this.add.graphics(); // black box to encompass progress bar
    this.loadText = this.add
      .text(Constants.GAME_WIDTH / 2, Constants.GAME_HEIGHT / 2, "", {
        font: "20px Tahoma" /*fill: "#fff"*/,
      })
      .setOrigin(0.5); // percentage text
    this.progressBox
      .fillStyle(0x555555, 0.5)
      .fillRect(Constants.GAME_WIDTH / 2 - 180, Constants.GAME_HEIGHT / 2 - 25, 360, 50);

    /* --- loading --- */

    // testing

    this.load.aseprite(
      "heartBeatTest",
      "assets/temp/aseprite/Heart_Beat_Animation.png",
      "assets/temp/aseprite/Heart_Beat_Animation.json"
    );
    this.load.aseprite(
      "heartBeatMaskTest",
      "assets/temp/aseprite/Heart_Mask.png",
      "assets/temp/aseprite/Heart_Mask.json"
    );
    this.load.aseprite(
      "heartBeatWave",
      "assets/temp/aseprite/Wave_Heart.png",
      "assets/temp/aseprite/Wave_Heart.json"
    );

    this.load.image("bg", "assets/temp/bg.png"); // https://sbcgamesdev.blogspot.com/2015/04/phaser-tutorial-manage-different-screen.html
    this.load.image("q1", "assets/temp/quaver.png");
    this.load.image("q2", "assets/temp/double_quaver.png");
    this.load.image("dash", "assets/temp/dash.png");
    this.load.image("question_mark", "assets/temp/question_mark.png");
    this.load.image("up_arrow", "assets/temp/up_arrow.png");
    this.load.image("boss_hp", "assets/temp/tuba_boss_hp.png");
    // this.load.aseprite(
    //   "priest",
    //   "assets/temp/aseprite/priest-wta.png",
    //   "assets/temp/aseprite/priest-wta.json"
    // );
    this.load.aseprite(
      "high-priest",
      "assets/temp/aseprite/Priest_Boss.png",
      "assets/temp/aseprite/Priest_Boss.json"
    );
    this.load.aseprite(
      "note-missile",
      "assets/temp/aseprite/Magic_Missiles.png",
      "assets/temp/aseprite/Magic_Missiles.json"
    );
    this.load.aseprite(
      "temp_sprite",
      "assets/temp/aseprite/temp_sprite.png",
      "assets/temp/aseprite/temp_sprite.json"
    );
    this.load.aseprite(
      "heart",
      "assets/temp/aseprite/HeartUpdate_Valve.png",
      "assets/temp/aseprite/HeartUpdate_Valve.json"
    );
    this.load.aseprite(
      "organ",
      "assets/temp/aseprite/Organ_Shooter.png",
      "assets/temp/aseprite/Organ_Shooter.json"
    );
    this.load.image("organProjectile", "assets/temp/aseprite/Organ_Projectile.png");
    this.load.aseprite(
      "tubaDwarf",
      "assets/temp/aseprite/tubaDwarf.png",
      "assets/temp/aseprite/tubaDwarf.json"
    );

    // music
    this.load.audio("music", "assets/temp/audio/high_priest_loop.ogg");
    // this.load.audio("music", "assets/temp/audio/high_priest_test.ogg");		// high_priest_test
    // this.load.audio("music", "assets/temp/audio/160BPM.ogg");					// 160BPM
    // this.load.audio("music", "assets/temp/audio/160BPMTEST.ogg");				// 160BPM TEST
    // this.load.audio("music", "assets/temp/audio/high_priest_fix.ogg");
    // sound effect

    this.load.audio("goodhitsound", "assets/temp/audio/Wet_Snare.ogg");
    // this.load.audio("greathitsound", "assets/temp/audio/Sparkly_Snare.ogg");
    this.load.audio("greathitsound", "assets/temp/audio/perfect.ogg");

    // NOTE: fonts are most likely loaded in the `BootScene`
  }

  // when all preloading is complete, get ready to change scenes to start the game
  create() {
    this.loadText.setText("Complete!");
    console.timeEnd("preload_total_time"); // debug timer (this can stay)

    if (isEmpty(this.loaded)) {
      this.time.delayedCall(250, () => this.cameras.main.fadeOut(1000)); // wait a few sec before timing out
      // this.time.delayedCall(2000, () => this.scene.start(Constants.TUTORIAL_SCENE_KEY));
      this.cameras.main.on("camerafadeoutcomplete", () => {
        this.scene.start(Constants.TUTORIAL_SCENE_KEY); // start the game
      });
    } else {
      this.percussiveJugg?.stop();
      this.add
        .text(Constants.GAME_WIDTH / 2, Constants.GAME_HEIGHT / 2 + 80, "LOAD ERROR!", {
          font: "50px Tahoma",
          color: "red",
        })
        .setOrigin(0.5); // percentage text
    }
  }
}
