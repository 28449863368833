import Constants, { MetronomeIntervals } from "../../Constants";
import Metronome from "../../metronome/Metronome";
import BaseSprite from "../base/BaseSprite";
import Player from "../player/Player";
import Heartbeat from "./Heartbeat";
import Heartbeats from "./Heartbeats";
import { metronomeIntervalExtender } from "../../helpers/Helpers";

export enum HeartbeatDirection {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

/**
 * The Heart
 */
export default class Heart extends BaseSprite {
  private heartbeatLeftGroup: Heartbeats;
  private heartbeatRightGroup: Heartbeats;
  private heartOverlapZoneGreat: Phaser.GameObjects.Zone;
  private heartOverlapZoneGood: Phaser.GameObjects.Zone;

  private keySpace: Phaser.Input.Keyboard.Key;

  private player: Player;
  private amountOfHeartBeats: number;
  private velocity: number;
  private gracePeriod: number;

  private goodHitSound: Phaser.Sound.BaseSound;
  private greatHitSound: Phaser.Sound.BaseSound;

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    metronome: Metronome,
    name: string,
    player: Player
  ) {
    super(scene, x, y, "heart", metronome, name);

    this.setScale(2); // temporary TODO - moved here so the heart scale can be set and accessed properly i.e. displayWidth
    this.setDepth(Constants.HEART_Z);

    this.player = player;
    this.amountOfHeartBeats = Constants.HEARTBEAT_AMOUNT;
    this.velocity =
      (this.displayWidth / 2 + Constants.HEARTBEAT_SPAWN_OFFSET) /
      (this.metronome.getMetronomeIntervalMS / 1000) /
      Constants.HEARTBEAT_VELOCITY_FACTOR;
    this.gracePeriod = Constants.HEART_ZONE_GREAT_WIDTH / 2 / this.velocity; // in second

    // TODO - just for testing
    this.keySpace = this.scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.SPACE);

    // create overlap zones specifically for the heart - we could use the heart itself, but since we are using 2 individual overlap areas (i.e. good / great), cleaner to do it this way
    this.heartOverlapZoneGreat = this.scene.add.zone(
      this.x,
      this.y,
      Constants.HEART_ZONE_GREAT_WIDTH,
      100
    );
    this.heartOverlapZoneGood = this.scene.add
      .zone(
        this.x - this.heartOverlapZoneGreat.width / 2,
        this.y,
        Constants.HEART_ZONE_GOOD_WIDTH,
        100
      )
      .setOrigin(1, 0.5);
    this.heartOverlapZoneGreat.name = Constants.HEART_ZONE_GREAT;
    this.heartOverlapZoneGood.name = Constants.HEART_ZONE_GOOD;
    this.scene.physics.world.enable([this.heartOverlapZoneGood, this.heartOverlapZoneGreat]); // zones do not have body physics by default, so enable them

    // initially play the normal animation, as thats where we will start
    // this.play({
    // 	key: "Hit_Good",
    // 	repeat: -1
    // });

    // construct the 2 heartbeat groups - lef and right

    this.heartbeatLeftGroup = new Heartbeats(
      scene,
      this,
      this.amountOfHeartBeats,
      this.velocity,
      HeartbeatDirection.LEFT
    );
    this.heartbeatRightGroup = new Heartbeats(
      scene,
      this,
      this.amountOfHeartBeats,
      -this.velocity,
      HeartbeatDirection.RIGHT
    );
    this.heartbeatLeftGroup.initialiseCollisionWithHeart(); // add collision detection for the entire left group - left heartbeats need this, right does NOT

    this.goodHitSound = scene.sound.add("goodhitsound", { volume: 0.2 });
    this.greatHitSound = scene.sound.add("greathitsound", { volume: 1, rate: 1.5 });

    this.player.on("enter-dash", () => {
      this.checkCollision();
    });

    // set up metronome listener event
    this.metronome.on(MetronomeIntervals.INTERVAL_BAR_1, () => {
      this.getHeartbeatLeftGroup.fireHeartbeat();
      this.getHeartbeatRightGroup.fireHeartbeat();
    });

    // metronomeIntervalExtender(
    //   "HEART",
    //   this.getMetronome,
    //   MetronomeIntervals.INTERVAL_BAR_1,
    //   () => {
    //     this.getHeartbeatLeftGroup.fireHeartbeat();
    //     this.getHeartbeatRightGroup.fireHeartbeat();
    //   },
    //   this,
    //   false
    // );
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Retrieve the GOOD heart zone (outer)
   */
  public get getHeartOverlapZoneGood(): Phaser.GameObjects.Zone {
    return this.heartOverlapZoneGood;
  }

  /**
   * Retrieve the GREAT heart zone (inner)
   */
  public get getHeartOverlapZoneGreat(): Phaser.GameObjects.Zone {
    return this.heartOverlapZoneGreat;
  }

  /**
   * Retrieve the GOOD heart zone BODY object (outer)
   */
  public get getHeartOverlapZoneGoodBody(): Phaser.Physics.Arcade.Body {
    return this.heartOverlapZoneGood.body as Phaser.Physics.Arcade.Body; // this is frowned upon but it's a quick dirty fix - technically supposed to use type guards (see this.isPhysicsBody)
  }

  /**
   * Retrieve the GREAT heart zone BODY object (inner)
   */
  public get getHeartOverlapZoneGreatBody(): Phaser.Physics.Arcade.Body {
    return this.heartOverlapZoneGreat.body as Phaser.Physics.Arcade.Body;
  }

  /**
   * Get the Heartbeat Left Group
   */
  public get getHeartbeatLeftGroup(): Heartbeats {
    return this.heartbeatLeftGroup;
  }

  /**
   * Get the Heartbeat Right Group
   */
  public get getHeartbeatRightGroup(): Heartbeats {
    return this.heartbeatRightGroup;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Functions
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  public update(scene: Phaser.Scene, time: number) {
    // this.heartbeatLeftGroup.update(time);
    // this.heartbeatRightGroup.update(scene, time);

    this.getHeartOverlapZoneGoodBody.debugBodyColor = this.getHeartOverlapZoneGoodBody.touching.none
      ? 0x000000
      : 0xffff00;
    this.getHeartOverlapZoneGreatBody.debugBodyColor = this.getHeartOverlapZoneGreatBody.touching
      .none
      ? 0x000000
      : 0xffff00;
  }

  public checkCollision() {
    // TODO VITAL MATT - this whole heart code isn't the best - searching for heartbeats and removing based on name / indexes
    // there is definitely a better way to go about this - will be worth figuring out proper solution later on ...
    // e.g. code is duplicated twice for good / great zones - if we wanted to add a 3rd zone, we would need to duplicate code again - not good

    // check for GREAT zone first as a priority, so then if great + good are overlapping at the same time, GREAT code is called first
    if (!this.getHeartOverlapZoneGreatBody.touching.none) {
      // console.log("================================================================");
      // console.log("GREAT BEFORE: ", this.heartbeatLeftGroup.getCollisionListGreat.map(e => e.name));

      // if key is pressed and there is a collision occurring, pop (shift) the first heartbeat off the collision list and reset the body
      const heartbeatLeft = this.heartbeatLeftGroup.getCollisionListGreat.shift();
      if (!heartbeatLeft) {
        console.error("ERROR, heartbeat (left) on great collision not found");
        return;
      }

      heartbeatLeft.emit("hitDetected", {
        heartbeat: heartbeatLeft,
        zone: this.getHeartOverlapZoneGreat,
      });

      heartbeatLeft.resetBody();

      // 100% chance
      if (Phaser.Math.Between(1, 100) >= 1) this.greatHitSound.play();

      // tricky part - find the heartbeat pair on the RIGHT side and reset that body as well ...
      // not sure if this is the most efficient way, but it works
      const heartbeatRight = this.heartbeatRightGroup.getChildren().find((e) => {
        return e.name === heartbeatLeft?.name;
      }) as Heartbeat | undefined;
      if (!heartbeatRight) {
        console.error("ERROR, heartbeat (right) on great collision not found");
        return;
      }

      heartbeatRight.resetBody();

      this.play({
        key: "Hit_Perfect",
        frameRate: 8, // default is 24 apparently
        // repeat: 1
      });

      // console.log("USER INPUT DETECTED - HIT GREAT ZONE", heartbeatLeft?.name);
      // console.log("GREAT AFTER: ", this.heartbeatLeftGroup.getCollisionListGreat.map(e => e.name));
    } else if (!this.getHeartOverlapZoneGoodBody.touching.none) {
      // console.log("================================================================");
      // console.log("GOOD BEFORE: ", heartbeatLeftGroup.getCollisionListGood.map(e => e.name));

      // if key is pressed and there is a collision occurring, pop (shift) the first heartbeat off the collision list and reset the body
      const heartbeatLeft = this.heartbeatLeftGroup.getCollisionListGood.shift();
      if (!heartbeatLeft) {
        console.error("ERROR, heartbeat (left) on good collision not found");
        return;
      }

      heartbeatLeft.emit("hitDetected", {
        heartbeat: heartbeatLeft,
        zone: this.getHeartOverlapZoneGood,
      });

      heartbeatLeft.resetBody();
      // this.goodHitSound.play();

      const heartbeatRight = this.heartbeatRightGroup.getChildren().find((e) => {
        return e.name === heartbeatLeft?.name;
      }) as Heartbeat | undefined;
      if (!heartbeatRight) {
        console.error("ERROR, heartbeat (right) on good collision not found");
        return;
      }

      heartbeatRight.resetBody();

      this.play({
        key: "Hit_Good",
        frameRate: 8, // default is 24 apparently
        // repeat: 1
      });

      // console.log("USER INPUT DETECTED - HIT GOOD ZONE", heartbeatLeft?.name);
      // console.log("GOOD AFTER: ", heartbeatLeftGroup.getCollisionListGood.map(e => e.name));
    } else {
      // console.log("USER INPUT DETECTED - BUT MISSED");
      this.scene.cameras.main.shake(100, 0.005);
    }
  }

  public toggleHeartDebug(bool: boolean) {
    this.heartbeatLeftGroup.toggleHeartbeatsDebug(bool);
    this.heartbeatRightGroup.toggleHeartbeatsDebug(bool);
  }

  // public play_normal() {}

  // state machine

  // play animations, normal, slow, fast

  // tempo
}
