import Constants from "../../Constants";

interface ILineStyle {
  fontSize: number;
  lineSpacing?: number; // additional spacing
  offsetStartX?: number; // TODO sill WIP - offset for a line (start x)
}

interface ILine {
  str: string;
  style: ILineStyle;
}

export default class TitleScene extends Phaser.Scene {
  skipTitleSequence = false;
  allowDebug = false;
  compoundDelay = true;
  startingPosition = 50; // relative to center of screen
  letterTweenDuration = 150; // lower is faster velocity of letters
  letterTweenDelay = 75; // high is slower duration between letters
  letterSpacing = 3; // global letter spacing, if desired
  lineSpacing = 0; // global line spacing, if desired
  letterSize = 65;
  letterLifeTimeMS = 4000;
  initialDelay = 1000;

  text: ILine[] = [
    { str: "ARCA", style: { fontSize: this.letterSize } },
    { str: "INFINITY", style: { fontSize: this.letterSize / 1.5, lineSpacing: 10 } },
    { str: "Presents", style: { fontSize: this.letterSize / 3, lineSpacing: 80 } },
  ];

  constructor() {
    super(Constants.TITLE_SCENE_KEY);
  }

  init() {
    console.log("TITLE SCENE");
  }

  create() {
    const mainContainer = this.addLines(this.text, this.compoundDelay, this.allowDebug);

    // align center (i.e. origin 0.5), with some slight startPosition offset for text to fly in
    mainContainer.setPosition(
      Constants.GAME_WIDTH / 2 - mainContainer.getBounds().width / 2 + this.startingPosition,
      Constants.GAME_HEIGHT / 2 - mainContainer.getBounds().height / 2
    );

    const linesContainer = mainContainer.list as Phaser.GameObjects.Container[];

    // TODO need to fix types of transitions, e.g. direct center with screen; supporting offsets; etc.
    // this is just very manual approach
    // also TODO, make sure these tweens are properly deleted when scene transitions - should do when scene.start runs
    linesContainer.map((line) => {
      line.iterate((letter: Phaser.GameObjects.Container) => {
        // intro tween
        this.tweens.add({
          targets: letter,
          //eslint-disable-next-line
          x: (((letter.x + Constants.GAME_WIDTH / 2) - line.getBounds().x) - (line.getBounds().width / 2)), // + (line.getData('offsetStartX') ?? 0), // get to center of screen
          duration: this.letterTweenDuration,
          ease: "cubic.out",
          delay: letter.getData("delay") + this.initialDelay,
          alpha: 1,
        });
        // outro tween
        this.tweens.add({
          targets: letter,
          x: letter.x - this.startingPosition * 2,
          duration: this.letterTweenDuration,
          ease: "cubic.in",
          delay: letter.getData("delay") + this.initialDelay + this.letterLifeTimeMS,
          alpha: this.allowDebug ? 1 : 0,
          onComplete: () => (this.allowDebug ? null : letter.removeAll(true)),
        });
      });
    });

    // Basic timer to transition to next screen - not ideal, since we should base it off tween completion, but cba
    this.time.delayedCall(this.letterLifeTimeMS + this.initialDelay + 3000, () => {
      this.scene.start(Constants.LOADING_SCENE_KEY);
    });
  }

  /**
   * Create multiple sets of lines, simulating a paragraph
   * @param lines
   * @param compoundDelay
   * @param allowDebug
   * @returns
   */
  addLines(
    lines: ILine[],
    compoundDelay = false,
    allowDebug = false
  ): Phaser.GameObjects.Container {
    const linesContainer: Phaser.GameObjects.Container[] = [];
    let totalPrevLength = 0;
    let totalPrevHeight = 0;
    let maxWidth = 0; // to align to the center - TODO when possible, make this an alignment setting, e.g. LEFT, CENTER, RIGHT

    // loop through the lines to get each line ...
    for (let i = 0; i < lines.length; i++) {
      // console.log("AddLines\t", lines[i]);

      const cont = this.addLine(lines[i], totalPrevLength, allowDebug);
      linesContainer.push(cont);

      const prevLine = linesContainer[i - 1] as Phaser.GameObjects.Container | null; // grab previous container to align multiple lines
      totalPrevHeight += (prevLine?.getBounds().height ?? 0) + (lines[i].style?.lineSpacing ?? 0);

      if (compoundDelay) totalPrevLength += lines[i].str.length;
      cont.y += totalPrevHeight;

      // recalculate max width each iteration to find largest width (used for aligning)
      if (cont.getBounds().width > maxWidth) maxWidth = cont.getBounds().width;
    }

    ////////////////////////////////////////////////////////////////////////////////////////////

    // ADHOC debug
    if (allowDebug) {
      // Guidelines for centering objecting
      const guideline = this.add.graphics({ lineStyle: { width: 4, color: 0xaa00aa } });
      const horizontalLine = new Phaser.Geom.Line(
        0,
        Constants.GAME_HEIGHT / 2,
        Constants.GAME_WIDTH,
        Constants.GAME_HEIGHT / 2
      );
      const verticalLine = new Phaser.Geom.Line(
        Constants.GAME_WIDTH / 2,
        0,
        Constants.GAME_WIDTH / 2,
        Constants.GAME_HEIGHT
      );
      guideline.strokeLineShape(horizontalLine);
      guideline.strokeLineShape(verticalLine);
    }

    ////////////////////////////////////////////////////////////////////////////////////////////

    // wrap all the linesContainer into one single container to align properly & return
    const mainContainer = this.add.container(0, 0, linesContainer);

    // align center, based on max width line
    const parentContainerWidth = mainContainer.getBounds().width;
    linesContainer.forEach((container) => {
      const containerWidth = container.getBounds().width;
      container.x += (parentContainerWidth - containerWidth) / 2;
    });

    return mainContainer;
  }

  /**
   * Use existing `addLetter` function to create a Line of text
   * This will allow individual manipulation against each letter, since each one is treated as its own bitmapText game object
   * @param line
   * @param allowDebug
   * @returns
   */
  addLine(
    line: ILine,
    totalPrevLength?: number | null,
    allowDebug = false
  ): Phaser.GameObjects.Container {
    const lineContainer = this.add
      .container(line.style.offsetStartX, 0)
      .setName(`${line.str}_Container`)
      .setData("offsetStartX", line.style.offsetStartX);

    // loop through each line to create the letter ...
    for (let i = 0; i < line.str.length; i++) {
      // console.log("AddLine\t\t", line.str[i], line.style.fontSize);

      const applyCompoundDelay = totalPrevLength ? totalPrevLength * this.letterTweenDelay : 0;

      const prevLetter = lineContainer.last as Phaser.GameObjects.Container | null; // also can use container.get(i-1)
      const prevLetterBitmapText = prevLetter?.first as Phaser.GameObjects.BitmapText | undefined;
      const textCont = this.addLetter(
        line.str[i],
        line.style.fontSize,
        (prevLetter?.x ?? 0) + (prevLetterBitmapText?.width ?? 0) + this.letterSpacing, // complex way of saying: next letter is IMMEDIATELY PLACED next to the one before
        this.letterTweenDelay * i + applyCompoundDelay,
        allowDebug
      );

      lineContainer.add(textCont); // then add to main parent container
    }

    return lineContainer;
  }

  /**
   * Add a single letter as a bitmapText
   * @param letter
   * @param fontSize
   * @param x
   * @param delay
   * @param allowDebug
   * @returns
   */
  addLetter(
    letter: string,
    fontSize: number,
    x: number,
    delay: number,
    allowDebug = false
  ): Phaser.GameObjects.Container {
    // console.log("AddLetter\t", letter, fontSize, x, delay);

    const letterContainer = this.add
      .container(x, 0)
      .setName(`${letter}_${x}_Container`)
      .setAlpha(0);
    letterContainer.setData({ delay }); // set custom 'delay' data, so tween knows what to do

    // create text - tried using dynamicBitmapText - too much hassle
    const text = this.add
      .bitmapText(0, 0, "default", letter, fontSize)
      .setName(letter)
      .setData("animDone", false);

    //////////////////////////////////////////////

    // create a debug rectangle around the text - annoyingly need to keep this so text is aligned center to page, otherwise unexpected behaviour occurs ...
    const debugRect = this.add
      .rectangle(text.x, text.y, text.width, text.height, 0xff0000, 0.5)
      .setOrigin(text.originX, text.originY);
    debugRect.setVisible(false);

    if (allowDebug) {
      debugRect.setVisible(true);
      letterContainer.setAlpha(1);
    }

    //////////////////////////////////////////////

    letterContainer.add(text); // add text and debug box, so everything gets tweened altogether as a container
    letterContainer.add(debugRect);

    return letterContainer;
  }
}
